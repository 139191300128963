import { cloneDeep } from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DropdownButtonMUI from "../dropdown-button-mui";
import MenuItem from "@mui/material/MenuItem";
import { injectIntl } from "react-intl";
import { getFormValues } from "redux-form";
import get from "lodash/get";
import { recoveredResourcesConstants } from "components/protect/recoveredResources/RecoveredResourcesConstant";
import AdvancedSearch from "../advanced-search-mui/index";
import CurrentSearchCriteria from "./CurrentSearchCriteria";
import SavedSearches from "./SavedSearches";
import ActionDropdown from "../action-dropdown-button-mui/index";
import InputSearch from "../input-search-mui/InputSearch";
import {
  showHideSaveSearchNClearAll,
  toggleAdvancedSearch,
  removeSearchTagsReload,
  clearAllSearchTagsReload,
  updateSearchText,
  updateCurrentSavedSearchName,
  updateSelectedFilterOptions,
  clearStateAdvancedParams,
  // twoFactorCheck,
  showDialog,
  Dialog
} from "../../../state/actions/actions";
import GridSettings from "../grid-settings-mui/index";
import IconMUI from "components/common/icon/IconMUI";
import Chip from "components/common/chip";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormattedMessage from "../formatted-message";
import Button from "../button";
import {
  makeShowAdvancedSearchState,
  makeAdvancedSearchOptionsState,
  makeShowCurrentSearchState,
  makeSearchTextState,
  makeSelectedRowsState,
  makeSearchOptionState,
  makeCheckboxSelectionState,
  makeClearAllOnDefaultState,
  makeRoleIdState,
  makeCurrentSearchOptionsState,
  makeBulkActionState,
  makeOptionsForTagsState,
  makeDateTimeRangeResetState,
  makeSelectedSavedSearchIdState,
  getOracleHostCount
} from "../../../state/selectors/CommonSelectors";
import { makeImpersonationViewState } from "../../../state/selectors/HomeSelectors";
import {
  SPOG_GRID_PROPERTIES,
  SECONDARY_NAVIGATION_TYPE,
  ADD_SOURCE_OPTIONS,
  AGENTLESS_VM_OPTIONS,
  ADD_SOURCE_OPTIONS_UDP,
  CONTEXTUAL_ACTION
} from "../../../utils/appConstants";
import SourceConstants from "../../protect/source/SourceConstants.js";
import { DETAIL_GROUP_ACTIONS } from "../../configure/source-groups/SourceGroupsConstants";
import { protectionType } from "components/protect/policy/create-policy/CreatePolicyConstants";
import { isRecoveryUser } from "utils";
import { getOriginalRoleId } from "state/selectors";
import { ID_PREFIX } from "configs/IdConfig";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

/**
 * Custom header component for the data-grid.
 */
const DataGridHeader = ({
  customerOrgId,
  isImpersonationView,
  organizationId,
  dispatch,
  twoFactorRequired,
  handleAdvancedSearchClick,
  disableManageSaveSearches,
  selectedSavedSearchId,
  options,
  advancedSearchGridOptions = [],
  showAdvancedSearch,
  bulkActions,
  showAdvancedSearchInput,
  isAdvSearchDisabled,
  selectedRows,
  groupActionTitle,
  groupActionHandler,
  selection,
  handleRemoveSourceClick,
  handleAddSourceClick,
  originalRoleId,
  addSourceOptionClick,
  formPolicy,
  handleSelectSourceClick,
  exportActions,
  intl,
  filterType,
  searchOption,
  currentSearchOptions,
  showAddSelectedSourcesButton,
  addSelectedSourcesHandler,
  isHypervPresent,
  disableClose,
  tagToDisable,
  dataGridID,
  ...props
}) => {
  // const [twoFactorReq, setTwoFactorReq] = useState(false);
  const [disableCreateReport, setDisableCreateReport] = useState(false);
  const secondaryNavigation = useSelector(
    state => state.reducer.secondaryNavigation
  );

  useEffect(() => {
    /* if (customerOrgId && isImpersonationView) {
      dispatch(getOrganization(customerOrgId, "impersonationView"));
    } else {
      dispatch(getOrganization(organizationId));
    } */
    // setTwoFactorReq(twoFactorRequired);
    // if (props.nav === SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS) {
    //   dispatch(twoFactorCheck());
    // }
  }, []);

  // const handleTwoFactorReq = () => {
  //   setTwoFactorReq(!twoFactorReq);
  //   dispatch(
  //     editOrganization({
  //       two_factor_required: !twoFactorReq
  //     })
  //   );
  // };

  const handleToggleAdvancedSearch = () => {
    dispatch(toggleAdvancedSearch());
  };

  const handleSearchTagsClose = (index, filterType, resourceId) => {
    dispatch(removeSearchTagsReload({ index, filterType, resourceId }));
  };

  const handleClearAllTags = () => {
    dispatch(clearAllSearchTagsReload());
    dispatch(clearStateAdvancedParams());
  };

  const handleSearchClick = () => {
    let advancedSearchOptions = [];
    const searchText = props.searchText ? props.searchText.trim() : "";
    if (!disableManageSaveSearches) {
      dispatch(showHideSaveSearchNClearAll(false));
    }
    if (handleAdvancedSearchClick) {
      if (selectedSavedSearchId && selectedSavedSearchId.length) {
        dispatch(updateCurrentSavedSearchName("", "")); //  user deselects a pre selected option
        dispatch(updateSearchText("")); //  user deselects a pre selected option
        advancedSearchOptions = cloneDeep(options);
        advancedSearchOptions.forEach(item => {
          // Otherwise, this is the one we want - return an updated value
          item.selectedOptions = [];
        });
        dispatch(updateSelectedFilterOptions([]));
      }
      handleAdvancedSearchClick({
        advancedSearchOptions,
        searchText,
        doNotUpdateOptions: true
      });
    }
  };

  const handleSearchTextChange = value => {
    dispatch(updateSearchText(value));
  };

  /**
   * Handle main rendering logic for the component.
   * @override
   */
  let toggleAdvancedSearchClass = showAdvancedSearch
    ? "collapse show d-flex"
    : "collapse";
  let filteredBulkActionOptions = bulkActions
    ?.map(actionKey => {
      if (typeof actionKey === "string") {
        return actionKey;
      } else if (actionKey?.key) {
        return actionKey?.key;
      }
    })
    ?.filter(
      d =>
        ![
          "convert_to_agentless",
          "deploy_policy",
          "deploy",
          "test_connection",
          "install_agent",
          "refresh",
          "resettwofactor",
          recoveredResourcesConstants.CONTEXTUAL_ACTION.FAILBACK_START,
          recoveredResourcesConstants.CONTEXTUAL_ACTION.FAILBACK_STOP,
          recoveredResourcesConstants.CONTEXTUAL_ACTION.FAILBACK_FINAL_STEP,
          SPOG_GRID_PROPERTIES.BULK_ACTION.ASSIGN_POLICY,
          SPOG_GRID_PROPERTIES.BULK_ACTION.LAUNCH_UDP_AGENT_UI,
          SPOG_GRID_PROPERTIES.BULK_ACTION.UPDATE,
          SPOG_GRID_PROPERTIES.BULK_ACTION.SHARE,
          SPOG_GRID_PROPERTIES.BULK_ACTION.EDIT_MANAGE_SCHEDULE_REPORT,
          "instantvm",
          SPOG_GRID_PROPERTIES.BULK_ACTION.CREATE_VM_INSTANT, //hiding vsb_pause,standbyvm, heart_beat_pause, standbyvmnetworkconfiguration for alpha
          SourceConstants.CONTEXTUAL_ACTION.HEARTBEAT_PAUSE,
          SourceConstants.CONTEXTUAL_ACTION.VIRTUAL_STANDBY_RESUME,
          SourceConstants.CONTEXTUAL_ACTION.VIRTUAL_STANDBY_PAUSE,
          SourceConstants.CONTEXTUAL_ACTION.STANDBY_VM,
          SourceConstants.CONTEXTUAL_ACTION.STANDBY_VM_NETWORK_CONFIGURATION,
          SourceConstants.CONTEXTUAL_ACTION.START_AR_TEST,
          CONTEXTUAL_ACTION.VIEW_LOG
        ].includes(d)
    );

  let sourceOptions =
    ["active", "trial"].indexOf(get(props.products, "udp.state")) === -1
      ? ADD_SOURCE_OPTIONS.filter(
          item => ADD_SOURCE_OPTIONS_UDP.indexOf(item.key) === -1
        )
      : get(props, "hypervisors") && get(props, "hypervisors").length === 0
      ? ADD_SOURCE_OPTIONS.filter(item => item.key !== "add-hypervisor")
      : ADD_SOURCE_OPTIONS;
  if (props?.oracleHostCount === 0) {
    sourceOptions = sourceOptions.filter(
      item => item.key !== "add-oracle-database"
    );
  }

  return (
    <Paper elevation={0}>
      <div className="grid-header-wrapper">
        <div className="search-wrapper d-flex justify-content-between align-items-center">
          <div className="input-wrapper d-flex align-items-center">
            {showAdvancedSearchInput && (
              <InputSearch
                isArrow={!isAdvSearchDisabled}
                placeholder={props.inputPlaceholder}
                handleCaretClick={handleToggleAdvancedSearch}
                handleSearchClick={handleSearchClick}
                searchText={props.searchText}
                handleSearchTextChange={handleSearchTextChange}
                disableSearchButton={showAdvancedSearch}
                onClickError={props.onClickError}
                retainSearchText={props.retainSearchText}
                testId={`${props.testId}_input_search`}
              />
            )}
          </div>
          <div className="buttons-wrapper d-flex align-items-center">
            {props.showSelectedRows && (
              <label
                htmlFor="selectRows"
                className="d-flex align-items-center m-0"
              >
                <FormattedMessage id="Selected" defaultMessage="Selected" />
                <Chip
                  htmlFor="type-select"
                  className={
                    selectedRows > 0
                      ? "selectlabelMUI mb-0 ml-2"
                      : "selectlabelNoneMUI mb-0 ml-2"
                  }
                  color="primary"
                  label={selectedRows}
                />
              </label>
            )}

            {props.showGridCount &&
              props.showGridCountText.map((item, index) => (
                <label
                  htmlFor="selected-row"
                  key={index}
                  className="d-flex align-items-center m-0"
                >
                  <FormattedMessage
                    id={item.label}
                    defaultMessage={item.label}
                  />
                  <Chip
                    htmlFor="grid-count-label"
                    className={
                      selectedRows > 0
                        ? "selectlabelMUI mb-0 ml-2"
                        : "selectlabelNoneMUI mb-0 ml-2"
                    }
                    color="primary"
                    label={item.number}
                  />
                </label>
              ))}
            {props.actions && (
              <ActionDropdown
                testId="xmkfR5"
                title={groupActionTitle ? groupActionTitle : "Actions"}
                color="secondary"
                variant="outlined"
                size="large"
                endIcon={<KeyboardArrowDownIcon />}
                options={
                  filterType !== SECONDARY_NAVIGATION_TYPE.SOURCE_GROUPS
                    ? filteredBulkActionOptions &&
                      filteredBulkActionOptions.length > 0
                      ? filteredBulkActionOptions
                      : [SPOG_GRID_PROPERTIES.BULK_ACTION_EMPTY]
                    : selectedRows > 0
                    ? [DETAIL_GROUP_ACTIONS.REMOVE_FROM_GROUP]
                    : [SPOG_GRID_PROPERTIES.BULK_ACTION_EMPTY]
                }
                onMenuItemClick={action => {
                  if (action !== SPOG_GRID_PROPERTIES.BULK_ACTION_EMPTY) {
                    groupActionHandler && groupActionHandler(action, selection);
                  }
                }}
                disabled={selectedRows === 0}
              />
            )}

            <Button
              testId="nrQZk0"
              variant="contained"
              color="secondary"
              size="large"
              disabled={selectedRows === 0 ? true : false}
              className={!props.removeSource ? "d-none" : ""}
              onClick={() => handleRemoveSourceClick(selection)}
              style={{ textTransform: "none", float: "left" }}
              content={
                <FormattedMessage
                  id="source.header.remove"
                  defaultMessage="Remove"
                />
              }
            />
            {props.gridHeaderButtons}
            <Button
              testId="wH60et"
              className={props.addSource || "d-none"}
              color="primary"
              content={
                <FormattedMessage
                  id="source.header.add"
                  defaultMessage="Add Source(s)"
                />
              }
              disabled={selectedRows === 0}
              onClick={() => handleAddSourceClick(selection)}
              size="large"
              variant="contained"
            />
            {!isRecoveryUser(originalRoleId) && props.showAddSourceComponent && (
              <DropdownButtonMUI
                testId="dGWP8I"
                title={<FormattedMessage id="source.header.add" />}
                color="primary"
                variant="contained"
                size="large"
                endIcon={<KeyboardArrowDownIcon />}
                customButtonStyle={{ marginLeft: 5 }}
              >
                {sourceOptions.map((value, index) => (
                  <MenuItem
                    id={`${ID_PREFIX}dGWP8I_menu_${value.key}`}
                    key={value.key}
                    onClick={() => addSourceOptionClick(value.key)}
                    style={{ fontSize: 14 }}
                  >
                    <FormattedMessage
                      id={value.id}
                      defaultMessage={value.defaultMessage}
                    />
                  </MenuItem>
                ))}
              </DropdownButtonMUI>
            )}
            {props.selectSource &&
              formPolicy &&
              formPolicy.policy_type &&
              formPolicy.policy_type !== protectionType.UDP_AGENTLESS_VM && (
                <Button
                  testId="7i7ibN"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSelectSourceClick}
                  style={{ textTransform: "none", float: "left" }}
                  content={
                    <FormattedMessage
                      id="source.header.select"
                      defaultMessage="Select Source(s)"
                    />
                  }
                />
              )}

            {formPolicy &&
              formPolicy.policy_type &&
              formPolicy.policy_type === protectionType.UDP_AGENTLESS_VM && (
                <DropdownButtonMUI
                  testId="ZUA6dt"
                  title={<FormattedMessage id="Sources" />}
                  className={props.selectSource ? "" : "d-none"}
                  color="primary"
                  variant="contained"
                  size="large"
                  endIcon={<KeyboardArrowDownIcon />}
                  customButtonStyle={{ marginLeft: 5 }}
                >
                  {AGENTLESS_VM_OPTIONS.map((value, index) => {
                    if (value.key === "add-hypervisor" && !isHypervPresent) {
                      //eslint-disable-next-line array-callback-return
                      return;
                    } else {
                      return (
                        <MenuItem
                          key={value.key}
                          onClick={() => {
                            if (value.key === "select-sources") {
                              handleSelectSourceClick();
                            } else if (value.key === "add-hypervisor") {
                              dispatch(
                                showDialog(
                                  Dialog.ADD_SOURCE_FROM_HYPERVISOR_POLICY
                                )
                              );
                            }
                          }}
                        >
                          <FormattedMessage
                            id={value.id}
                            defaultMessage={value.defaultMessage}
                          />
                        </MenuItem>
                      );
                    }
                  })}
                </DropdownButtonMUI>
              )}
            {!isRecoveryUser(originalRoleId) && (
              <Button
                testId="cuHT8C"
                className={
                  props.showCreateGroupButton ? "add-destination" : "d-none"
                }
                color="primary"
                content={
                  <FormattedMessage
                    id="create.group"
                    defaultMessage="Create Group"
                  />
                }
                onClick={props.createGroupHandler}
                size="large"
                variant="contained"
              />
            )}

            <Button
              testId="lmQiO6"
              className={
                props.showAddSourcesToGroupButton ? "add-destination" : "d-none"
              }
              color="primary"
              content={
                <FormattedMessage
                  id="add.sources.to.group"
                  defaultMessage="Add Sources to Group"
                />
              }
              onClick={props.addSourcesToGroupHandler}
              size="large"
              variant="contained"
            />

            <Button
              testId="g0qswI"
              className={
                showAddSelectedSourcesButton ? "add-destination" : "d-none"
              }
              color="primary"
              content={
                <FormattedMessage
                  id="add.selected.sources"
                  defaultMessage="Add Selected Sources"
                />
              }
              disabled={selectedRows === 0 ? true : false}
              onClick={addSelectedSourcesHandler}
              size="large"
              variant="contained"
            />

            <Button
              testId="KyQptn"
              className={props.showCancelButton ? "add-destination" : "d-none"}
              color="primary"
              content={
                <FormattedMessage
                  id="save.search.cancel"
                  defaultMessage="save.search.cancel"
                />
              }
              onClick={props.cancelHandler}
              size="large"
              variant="contained"
            />

            {!isRecoveryUser(originalRoleId) && props.showCreateReport && (
              <Button
                testId="pmuZUI"
                className="create-report"
                color="primary"
                content={
                  <FormattedMessage
                    id="report.create_report"
                    defaultMessage="Create Report"
                  />
                }
                disabled={disableCreateReport}
                onClick={() => {
                  props.createReportHandler();
                  setDisableCreateReport(true);
                  setTimeout(() => {
                    setDisableCreateReport(false);
                  }, 3000);
                }}
                size="large"
                variant="contained"
              />
            )}

            {exportActions && exportActions.length > 0 && (
              <ActionDropdown
                testId="bRHq88"
                title={intl.formatMessage({ id: "export-action" })}
                color="secondary"
                variant="outlined"
                size="large"
                endIcon={<KeyboardArrowDownIcon />}
                options={exportActions}
                onMenuItemClick={props.handleExportReport}
              />
            )}

            {props.showColumnSettings && (
              <GridSettings
                testId="JMmd5u"
                dataGridID={dataGridID}
                className={
                  props.disableGridSettings
                    ? "grid-dropdown d-none"
                    : "grid-dropdown"
                }
                disableManageSaveSearches={disableManageSaveSearches}
                columnsModelData={props.columnsModel}
                title={<IconMUI icon={SettingsOutlinedIcon} />}
                filterType={filterType}
                variant="default"
              />
            )}
          </div>
        </div>

        {!isAdvSearchDisabled && (
          <AdvancedSearch
            isRenderedInModal={false}
            className={toggleAdvancedSearchClass}
            options={options}
            loaderValue={props.loaderValue}
            localSearch={props.localSearch}
            placeholder={props.placeholder}
            isMenuCloseOnSelect={props.isMenuCloseOnSelect}
            isDropdownDisabled={props.isDropdownDisabled}
            isMultiSelect={props.isMultiSelect}
            handleToggleAdvancedSearch={handleToggleAdvancedSearch}
            handleAdvancedSearchClick={handleAdvancedSearchClick}
            handleSearchClick={handleSearchClick}
            disableManageSaveSearches={disableManageSaveSearches}
            dispatch={dispatch}
            searchOption={searchOption}
            searchText={props.searchText}
            filterType={filterType}
            resourceId={props.resourceId}
            skipDefaultOption={false}
            resetDTRComponent={props.dateTimeRangeResetFlag}
            currentSearchOptions={currentSearchOptions}
            selectedSavedSearchId={selectedSavedSearchId}
          />
        )}
        {
          <React.Fragment>
            {props.showCurrentSearch &&
            !props.showSaveSearchClearAllOnDefault ? (
              <CurrentSearchCriteria
                options={props.optionsForCurrentSearchTags}
                advSearchOptions={
                  options &&
                  options.map(item => ({
                    key: item.key,
                    name: item.name
                  }))
                }
                hideSaveSearchLink={props.hideSaveSearchLink}
                searchOption={searchOption}
                filterType={filterType}
                handleSearchTagsClose={handleSearchTagsClose}
                handleClearAllTags={handleClearAllTags}
                resourceId={props.resourceId}
                handleAdvancedSearchClick={handleAdvancedSearchClick}
                classes="saved-filter-wrapper d-flex"
                disableClose={disableClose}
                tagToDisable={tagToDisable}
                dataGridID={dataGridID}
              />
            ) : (
              !isAdvSearchDisabled &&
              props.showSavedSearchList && (
                <SavedSearches
                  showUserDefinedSearch={props.showUserDefinedSearch}
                  handleAdvancedSearchClick={handleAdvancedSearchClick}
                  filterType={filterType}
                  options={options}
                  totalSize={props.totalSize}
                />
              )
            )}
          </React.Fragment>
        }
      </div>
    </Paper>
  );
};

DataGridHeader.defaultProps = {
  removeSource: false,
  selectSource: false,
  addSource: false,
  actions: false,
  showSelectedRows: true,
  disableGridSettings: false,
  showAddSourceComponent: false,
  showSavedSearchList: true,
  showColumnSettings: true,
  showGridCount: false,
  showCurrentSearch: true,
  showAdvancedSearchInput: true,
  options: [],
  resourceId: "",
  placeholder: "Select",
  inputPlaceholder: "Search",
  isMenuCloseOnSelect: false,
  isDropdownDisabled: false,
  isMultiSelect: true,
  showAdvancedSearch: true,
  searchText: "",
  showSaveSearchClearAllOnDefault: true,
  disableManageSaveSearches: false,
  isAdvSearchDisabled: false,
  selectedRows: 0,
  selection: [],
  groupActionTitle: "Actions",
  searchOption: {},
  hideSaveSearchLink: false,
  showCreateReport: false,
  showCancelButton: false,
  exportActions: [],
  showGridCountText: false,
  totalSize: 0,
  showCreateGroupButton: false,
  showAddSourcesToGroupButton: false,
  showAddSelectedSourcesButton: false,
  showUserDefinedSearch: true,
  bulkActions: [],
  currentSearchOptions: [],
  addSelectedSourcesHandler: () => {},
  groupActionHandler: () => {},
  addSourcesToGroupHandler: () => {},
  handleExportReport: () => {},
  createReportHandler: () => {},
  handleRemoveSourceClick: () => {},
  handleSelectSourceClick: () => {},
  addSourceOptionClick: () => {},
  handleAddSourceClick: () => {},
  cancelHandler: () => {},
  onClickError: true,
  retainSearchText: false
};

DataGridHeader.propTypes = {
  showUserDefinedSearch: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  isMenuCloseOnSelect: PropTypes.bool,
  isDropdownDisabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  showAdvancedSearch: PropTypes.bool,
  showCurrentSearch: PropTypes.bool,
  searchText: PropTypes.string,
  filterType: PropTypes.string.isRequired,
  removeSource: PropTypes.bool,
  selectSource: PropTypes.bool,
  actions: PropTypes.bool,
  showSelectedRows: PropTypes.bool,
  handleAdvancedSearchClick: PropTypes.func,
  handleAddSourceClick: PropTypes.func.isRequired,
  addSourceOptionClick: PropTypes.func.isRequired,
  handleSelectSourceClick: PropTypes.func.isRequired,
  handleRemoveSourceClick: PropTypes.func.isRequired,
  showSaveSearchClearAllOnDefault: PropTypes.bool,
  disableGridSettings: PropTypes.bool,
  disableManageSaveSearches: PropTypes.bool,
  showSavedSearchList: PropTypes.bool,
  showColumnSettings: PropTypes.bool,
  showGridCount: PropTypes.bool,
  isAdvSearchDisabled: PropTypes.bool,
  selectedRows: PropTypes.number,
  selection: PropTypes.arrayOf(PropTypes.shape({})),
  addSource: PropTypes.bool,
  showAddSourceComponent: PropTypes.bool,
  searchOption: PropTypes.shape(),
  columnsModel: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideSaveSearchLink: PropTypes.bool,
  showGridCountText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.bool
  ]),
  totalSize: PropTypes.number,
  showCreateReport: PropTypes.bool,
  createReportHandler: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  exportActions: PropTypes.arrayOf(PropTypes.string),
  handleExportReport: PropTypes.func.isRequired,
  showCreateGroupButton: PropTypes.bool,
  createGroupHandler: PropTypes.func.isRequired,
  showAddSourcesToGroupButton: PropTypes.bool,
  addSourcesToGroupHandler: PropTypes.func.isRequired,
  groupActionHandler: PropTypes.func.isRequired,
  groupActionTitle: PropTypes.string,
  showAddSelectedSourcesButton: PropTypes.bool,
  addSelectedSourcesHandler: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
  cancelHandler: PropTypes.func.isRequired,
  showAdvancedSearchInput: PropTypes.bool,
  optionsForCurrentSearchTags: PropTypes.PropTypes.shape({}).isRequired,
  bulkActions: PropTypes.arrayOf(PropTypes.string),
  dateTimeRangeResetFlag: PropTypes.bool.isRequired,
  currentSearchOptions: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSavedSearchId: PropTypes.string.isRequired,
  onClickError: PropTypes.bool,
  retainSearchText: PropTypes.bool
};

/**
 * Using makeMapStateToProps to memoize a selectorFunction for reusability
 * This will create a new instance of selector function
 * every time it is needed for proper memoization in multiple components.
 */
const makeMapStateToProps = () => {
  /**
   * create a new instance of selector function
   * This makes it independent of any other selector function
   * and it will properly memoize across multiple mounted & connected components.
   */
  const getShowAdvancedSearchState = makeShowAdvancedSearchState();
  const getAdvancedSearchOptionsState = makeSearchOptionState();
  const getAdvancedOptionsState = makeAdvancedSearchOptionsState();
  const getCurrentSearchOptionsState = makeCurrentSearchOptionsState();
  const getShowCurrentSearchState = makeShowCurrentSearchState();
  const getSearchTextState = makeSearchTextState();
  const getSelectedRowsState = makeSelectedRowsState();
  const getCheckboxSelectionState = makeCheckboxSelectionState();
  const getClearAllOnDefaultState = makeClearAllOnDefaultState();
  const getIsImpersonationView = makeImpersonationViewState();
  const getRoleId = makeRoleIdState();
  const getBulkActionState = makeBulkActionState();
  const getOptionsForTagsState = makeOptionsForTagsState();
  const getDateTimeRangeResetState = makeDateTimeRangeResetState();
  const getSelectedSavedSearchIdState = makeSelectedSavedSearchIdState();

  const mapStateToProps = (state, props) => {
    let advancedOptions = getAdvancedOptionsState(state, props);
    return {
      /**
       * Use selector function instance to update Props.
       */
      hypervisors: state.protectReducer.hypervisorsList,
      oracleHostCount: getOracleHostCount(state),
      products: state.api.entitlementsProducts.entitlementsProducts,
      showAdvancedSearch: getShowAdvancedSearchState(state, props),
      options:
        advancedOptions && advancedOptions.length > 0
          ? advancedOptions
          : props.advancedSearchGridOptions,
      loaderValue: advancedOptions && advancedOptions.length > 0 ? false : true,
      currentSearchOptions: getCurrentSearchOptionsState(state, props),
      optionsForCurrentSearchTags: getOptionsForTagsState(state, props),
      searchOption: getAdvancedSearchOptionsState(state, props),
      showCurrentSearch: getShowCurrentSearchState(state, props),
      searchText: getSearchTextState(state, props),
      selectedRows: getSelectedRowsState(state, props),
      selection: getCheckboxSelectionState(state, props),
      showSaveSearchClearAllOnDefault: getClearAllOnDefaultState(state, props),
      roleId: getRoleId(state, props),
      originalRoleId: getOriginalRoleId(state),
      isImpersonationView: getIsImpersonationView(state, props),
      bulkActions: getBulkActionState(state, props),
      dateTimeRangeResetFlag: getDateTimeRangeResetState(state, props),
      selectedSavedSearchId: getSelectedSavedSearchIdState(state, props),
      twoFactorRequired:
        state.login.organizationInfo &&
        state.login.organizationInfo.two_factor_required,
      nav: state.reducer.secondaryNavigation,
      organizationId: state.login.organization_id,
      formPolicy: getFormValues("NEW_POLCIY_BASICS")(state),
      customerOrgId: state.reducer.customerOrgId
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(injectIntl(DataGridHeader));
