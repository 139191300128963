/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */

import _ from "lodash";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import moment from "moment";
import {
  getNodeAtPath,
  addNodeUnderParent,
  removeNodeAtPath
} from "react-sortable-tree";
import {
  VSB_IDENTIFIERS,
  VSB_FILTER_ATTRIBUTES
} from "components/protect/recoveredResources/virtualStandby/virtualStandbyConstants";
import * as constant from "./appConstants";
import { POLICY_TYPES } from "../components/protect/policy/create-policy/destinations/DestinationConstants";
// eslint-disable-next-line import/no-cycle
import { getChartParameters } from "../components/analyze/reports/charts/Dashboard";

export const getWidgetOrder = (layout, index) => {
  if (index >= layout.length) {
    return index;
  }
  const { x } = layout[index];
  const { y } = layout[index];

  const order = x + 1 + 2 * y;
  return order;
};

export const isWidgetExpanded = (layout, index) => {
  if (index >= layout.length) {
    return false;
  }
  return layout[index].w === 2;
};

export const getPercentageCompletion = (transferSize, estimateSize) => {
  if (transferSize && estimateSize) {
    return ((transferSize / estimateSize) * 100).toFixed();
  }
  return 0;
};

/**
 * Will get the filtered result by applying criteria to the dataArray passed
 * @param dataArray Data array
 * @param criteria {searchText:<any>, advancedSearchOptions:<Array>}
 * @param searchNameField  - Filed name for search text criteria
 * @returns {*}
 */
export const filterResultByCriteria = (
  dataArray,
  criteria,
  searchNameField
) => {
  const filteredResult = [];
  if (criteria && dataArray) {
    dataArray.forEach(data => {
      let isCriteriaSucceed = false;
      let nameCriteria = true;

      if (
        criteria.searchText &&
        data[searchNameField]
          .toLowerCase()
          .indexOf(criteria.searchText.toLowerCase()) === -1
      ) {
        nameCriteria = false;
      }

      let succeedDropdowns = 0;
      let selectedDropdowns = 0;
      criteria.advancedSearchOptions.forEach(value => {
        if (value.selectedOptions.length > 0) {
          selectedDropdowns += 1;
          /**
           *  Logical OR Criteria on selectedOptions
           */
          let dropdown = false;
          value.selectedOptions.map(selectedOption => {
            if (
              data[value.key] instanceof Array &&
              data[value.key].indexOf(selectedOption.value) !== -1
            ) {
              dropdown = true;
            } else if (data[value.key] === selectedOption.value) {
              dropdown = true;
            }
          });
          if (dropdown) {
            succeedDropdowns += 1;
          }
        }
      });

      /**
       * Logical AND criteria for all dropdowns
       */
      if (nameCriteria && selectedDropdowns === succeedDropdowns) {
        isCriteriaSucceed = true;
      } else {
        isCriteriaSucceed = false;
      }

      if (isCriteriaSucceed) {
        filteredResult.push(data);
      }
    });
  } else {
    return dataArray;
  }

  return filteredResult;
};

/**
 *
 * @param queryStringToAppend : current query string append and made by Available search options for advanced search
 * @param advancedSearchOptions : Available search options for advanced search
 * @returns {string}
 */
export function getQueryStringByAdvanceSearchOptions(
  queryStringToAppend,
  advancedSearchOptions
) {
  let queryString = "";
  let queryStringToReturn = "";
  if (advancedSearchOptions) {
    advancedSearchOptions.forEach(option => {
      switch (option.type) {
        case "date-picker":
          if (
            option.selectedOptions &&
            Object.keys(option.selectedOptions).length
          ) {
            if (queryString.length > 0) {
              queryString += "&";
            }
            queryString += `${option.key}=%3E${option.selectedOptions.start}&${option.key}=%3C${option.selectedOptions.end}`;
          }
          break;

        case "date-range-picker":
          if (
            option.selectedOptions &&
            Object.keys(option.selectedOptions).length
          ) {
            if (queryString.length > 0) {
              queryString += "&";
            }
            const { startTime, endTime } = getTimestamp(option.selectedOptions);
            if (startTime && endTime) {
              if (option.name === "report_date_range") {
                queryString += `start_time_ts=%3E${startTime}&end_time_ts=%3C${endTime}&${
                  constant.TIME_RESOLUTION
                }=${
                  constant.TIME_RESOLUTION_PARAMS[option.selectedOptions.type]
                }`;
              } else {
                queryString += `${option.key}=%3E${startTime}&${
                  option.key
                }=%3C${endTime}&${constant.TIME_RESOLUTION}=${
                  constant.TIME_RESOLUTION_PARAMS[option.selectedOptions.type]
                }`;
              }
            }
          }
          break;
        case constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE:
          if (option.selectedOptions && option.selectedOptions.length) {
            if (queryString.length > 0) {
              queryString += "&";
            }
            const optionArr = option.selectedOptions.map(item => {
              if (item.noParam) return;
              return item.id ? item.id : item.value;
            });
            queryString += `${option.type}=${encodeURIComponent(
              optionArr.join("|")
            )}`;
          }
          break;
        default:
          if (option.selectedOptions && option.selectedOptions.length) {
            if (queryString.length > 0) {
              queryString += "&";
            }
            let optionArr = [];
            optionArr = option.selectedOptions.map(item => {
              if (item === "false" || item === "true") return item;
              // Checking source state filter and remove VSB identifier to send BE supported source state filters.
              // To fix bug 1039192
              if (
                option.key === VSB_FILTER_ATTRIBUTES.SOURCE_STATE &&
                item.value.includes(VSB_IDENTIFIERS[option.key])
              ) {
                return item.value.replace(VSB_IDENTIFIERS[option.key], "");
              }
              return item.id ? item.id : item.value;
            });
            queryString += `${option.key}=${encodeURIComponent(
              optionArr.join("|")
            )}`;
          }
          break;
      }
    });
  }

  if (queryStringToAppend !== "" && queryString !== "") {
    queryStringToReturn = `${queryStringToAppend}&${queryString}`;
  } else if (queryString !== "") {
    queryStringToReturn = queryString;
  } else {
    queryStringToReturn = queryStringToAppend;
  }
  return queryStringToReturn;
}

export function getQueryStringWithoutAdvanceSearchOptionsForJobs(
  queryStringToAppend,
  defaultData
) {
  let queryString = "";
  let queryStringToReturn = "";
  Object.keys(defaultData).map(key => {
    // eslint-disable-next-line default-case
    switch (key) {
      case "job_status":
      case "job_type":
      case "sub_organization_id":
      case "policy_id":
      case "group_id":
        if (defaultData[key].length > 0) {
          if (queryString.length > 0) {
            queryString += "&";
          }
          queryString += `${key}=${encodeURIComponent(
            defaultData[key].join("|")
          )}`;
        }
        break;
      case "start_time_ts":
        if (queryString.length > 0) {
          queryString += "&";
        }
        // eslint-disable-next-line no-case-declarations
        const { startTime, endTime } = getTimestamp(defaultData[key]);

        queryString += `${key}=%3E${startTime}&${key}=%3C${endTime}&${
          constant.TIME_RESOLUTION
        }=${constant.TIME_RESOLUTION_PARAMS[defaultData[key].type]}`;
        break;
    }
  });

  if (queryStringToAppend !== "" && queryString !== "") {
    queryStringToReturn = `${queryStringToAppend}&${queryString}`;
  } else if (queryString !== "") {
    queryStringToReturn = queryString;
  } else {
    queryStringToReturn = queryStringToAppend;
  }
  return queryStringToReturn;
}

export function getSelectedFilterOption(filterOption) {
  if (filterOption.selectedOptions && filterOption.selectedOptions.length) {
    return filterOption.selectedOptions.map(item => {
      if (item.noParam) return;
      if (
        filterOption.key === "group_id" &&
        _.get(item, "id") === undefined &&
        _.get(item, "value") === undefined
      ) {
        return item;
      }
      return item.id ? item.id : item.value;
    });
  }
  return "";
}
export function getTimestamp(value) {
  switch (value.type) {
    case constant.DATE_RANGE_OPTIONS.TODAY:
      return {
        startTime: moment
          .utc()
          .startOf("day")
          .unix(),
        endTime: moment.utc().unix()
      };

    case constant.DATE_RANGE_OPTIONS.LAST_24_HOURS:
      return {
        startTime: moment
          .utc()
          .subtract(1, "day")
          .unix(),
        endTime: moment.utc().unix()
      };

    case constant.DATE_RANGE_OPTIONS.LAST_7_DAYS:
      return {
        startTime: moment
          .utc()
          .subtract(7, "days")
          .add(1, "day")
          .startOf("day")
          .unix(),
        endTime: moment.utc().unix()
      };

    case constant.DATE_RANGE_OPTIONS.LAST_2_WEEKS:
      return {
        startTime: moment
          .utc()
          .subtract(14, "days")
          .add(1, "day")
          .startOf("day")
          .unix(),
        endTime: moment.utc().unix()
      };

    case constant.DATE_RANGE_OPTIONS.LAST_1_MONTH:
      return {
        startTime: moment
          .utc()
          .subtract(1, "month")
          .add(1, "day")
          .startOf("day")
          .unix(),
        endTime: moment.utc().unix()
      };

    case constant.DATE_RANGE_OPTIONS.LAST_3_MONTHS:
      return {
        startTime: moment()
          .subtract(3, "months")
          .add(1, "day")
          .startOf("day")
          .unix(),
        endTime: moment().unix()
      };

    case constant.DATE_RANGE_OPTIONS.LAST_6_MONTHS:
      return {
        startTime: moment()
          .subtract(6, "months")
          .add(1, "day")
          .startOf("day")
          .unix(),
        endTime: moment().unix()
      };

    case constant.DATE_RANGE_OPTIONS.LAST_1_YEAR:
      return {
        startTime: moment
          .utc()
          .subtract(1, "year")
          .add(1, "day")
          .startOf("day")
          .unix(),
        endTime: moment.utc().unix()
      };

    case constant.DATE_RANGE_OPTIONS.CUSTOM:
      return {
        startTime: value.start_ts,
        endTime: value.end_ts
      };

    default:
      return {
        startTime: 0,
        endTime: 0
      };
  }
}
/**
 * To generate timestamp
 * */
export function getUnixTime(range, newDate = "") {
  let pastDate = "";
  if (range) {
    if (range !== "custom") {
      const date = new Date();
      pastDate = date.setDate(date.getDate() - parseInt(range));
      return Math.round(pastDate / 1000);
    }
  }
  if (newDate !== "undefined") {
    const date = new Date(newDate);
    pastDate = date.setDate(date.getDate());
    return Math.round(pastDate / 1000);
  }
}

/**
 *
 * @param queryStringToAppend : current query string append and made by Available search options for advanced search
 * @param searchText : name search text
 * @param stateReducersearchOption
 * @param nameSearchKey : key to filter for name
 * @returns {string}
 */
export function getQueryStringBySearchNameText(
  queryStringToAppend,
  searchText,
  stateReducersearchOption,
  nameSearchKey
) {
  let criterion = "";
  // eslint-disable-next-line eqeqeq
  if (searchText != undefined && searchText != "") {
    criterion = `${nameSearchKey}=${encodeURIComponent(searchText)}`;
  } else if (
    // eslint-disable-next-line eqeqeq
    stateReducersearchOption != undefined &&
    // eslint-disable-next-line eqeqeq
    stateReducersearchOption != ""
  ) {
    // criterion = `${nameSearchKey}=${stateReducersearchOption}`;
    criterion = `${nameSearchKey}=${encodeURIComponent(
      stateReducersearchOption
    )}`;
  }
  let queryString = "";
  if (queryStringToAppend !== "" && criterion !== "") {
    queryString = `${queryStringToAppend}&${criterion}`;
  } else if (criterion !== "") {
    queryString = criterion;
  } else {
    queryString = queryStringToAppend;
  }

  return queryString;
}

/**
 *
 * @param queryStringToAppend : current query string append and made by Available search options for advanced search
 * @param navigation : navigation condition for type base
 * @returns {string}
 */
export function getQueryStringByType(queryStringToAppend, navigation, typeKey) {
  let filterType;

  switch (navigation) {
    case constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES:
      // eslint-disable-next-line no-case-declarations
      const separator = encodeURIComponent("|");
      filterType = `machine${separator}udp_windows${separator}udp_linux${separator}udp_linux_backup_server`;
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.ORACLE_HOSTS:
      // eslint-disable-next-line no-case-declarations
      const separators = encodeURIComponent("|");
      filterType = `windows_oracle_host${separators}linux_oracle_host`;
      break;
    case constant.SOURCE_TYPE_WINDOWS_ORACLE_HOST:
      filterType = `windows_oracle_host`;
      break;
    case constant.SOURCE_TYPE_LINUX_ORACLE_HOST:
      filterType = `linux_oracle_host`;
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES:
      filterType = "agentless_vm";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.MACHINES:
      filterType = "machine";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS:
      filterType = "shared_folder";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBYS:
      filterType = "virtual_standby";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY:
      filterType = "virtual_standbys";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS:
      filterType = "instant_vm";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.SHARE_POINT:
      filterType = "share_point";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.OFFICE_365:
      filterType = "office_365";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.CLOUD_VOLUMES:
      filterType = "cloud_direct_volume";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.DEDUPE_STORE:
      filterType = "cloud_hybrid_store";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.SHARE_FOLDER:
      filterType = "shared_folder";
    // eslint-disable-next-line no-fallthrough
    case constant.SECONDARY_NAVIGATION_TYPE.UDP_LINUX:
      filterType = "udp_linux";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.UDP_ORACLE:
      filterType = "udp_oracle";
      break;
    default:
      filterType = "";
  }
  let queryString = ``;
  // eslint-disable-next-line eqeqeq
  if (filterType !== "" && queryStringToAppend !== "") {
    queryString = `${typeKey}=${filterType}&${queryStringToAppend}`;
  } else if (filterType !== "") {
    queryString = `${typeKey}=${filterType}`;
  } else {
    queryString = queryStringToAppend;
  }

  return queryString;
}

/**
 * Handle get array
 *
 * @param {array} stateArray
 * @param {array} actionArray current options in reducer state
 * @param {string} selectedSavedSearchName current saved search selected from reducers state
 * @param {array} searchStr current searched string in reducer state
 */
export function getSelecetedOptionsInArray(
  stateArray = [],
  actionArray = [],
  selectedSavedSearchName = "",
  currentSearchText = "",
  saveSearchName = "",
  savedSearchDataLength = 0
) {
  let updateObj = [];
  if (stateArray.length > 0) {
    stateArray.map(stateItem => {
      if (stateItem.name === selectedSavedSearchName) {
        updateObj = { ...stateItem };
      }
    });
  }
  updateObj.options = [];
  actionArray.map(actionItem => {
    if (actionItem.selectedOptions.length > 0) {
      updateObj.options.push({ ...actionItem });
    }
  });
  updateObj.searchString = currentSearchText;
  updateObj.options.map(objItem => {
    delete objItem.name;
    delete objItem.options;
  });
  // eslint-disable-next-line eqeqeq
  if (saveSearchName != "") {
    updateObj.name = saveSearchName;
    updateObj.id = savedSearchDataLength + 1;
  }
  return updateObj;
}

/**
 * function makes the options array by calling with data
 * for advance search drop down values
 *
 * @param {array} advanceOptionsSets constant array of dropdown values
 * @param {array} options array of dropdown values
 * @param {array} name dropdown name
 * @param {string} optionKey dropdown option key
 * @param {string} filterKey data field key
 */
export function makeAdvanceSearchOptionsArray(
  advanceOptionsSets,
  options,
  name,
  optionKey,
  filterKey
) {
  const makeOptionsObject = {};
  makeOptionsObject.name = name;
  makeOptionsObject.key = optionKey;
  for (let index = 0; index < advanceOptionsSets.length; index += 1) {
    if (advanceOptionsSets[index].key === optionKey) {
      return null;
    }
  }
  makeOptionsObject.options = [];
  options &&
    options.map(optionObject => {
      makeOptionsObject.options.push({
        label: optionObject[filterKey],
        value: optionObject[filterKey],
        id: optionObject[optionKey]
      });
    });
  makeOptionsObject.selectedOptions = [];
  return makeOptionsObject;
}

export function makeAdvanceSearchOptions(
  advanceOptionsSets,
  options,
  name,
  urlParamName,
  valueKey,
  idKey
) {
  const makeOptionsObject = {};
  makeOptionsObject.name = name;
  makeOptionsObject.key = urlParamName;
  for (let index = 0; index < advanceOptionsSets.length; index += 1) {
    if (advanceOptionsSets[index].key === urlParamName) {
      return null;
    }
  }
  makeOptionsObject.options = [];
  options &&
    options.map(optionObject => {
      makeOptionsObject.options.push({
        label: optionObject[valueKey],
        value: optionObject[valueKey],
        id: optionObject[idKey]
      });
    });
  makeOptionsObject.selectedOptions = [];
  return makeOptionsObject;
}

export function makeAdvanceSearchOptionsArrayWithType(
  advanceOptionsSets,
  options,
  option
) {
  const makeOptionsObject = {};
  makeOptionsObject.name = option.NAME;
  makeOptionsObject.key = option.KEY;
  for (let index = 0; index < advanceOptionsSets.length; index += 1) {
    if (advanceOptionsSets[index].key === option.KEY) {
      return null;
    }
  }
  makeOptionsObject.options = [];
  options &&
    options.map(optionObject => {
      makeOptionsObject.options.push({
        label: optionObject[option.FIELD],
        value: optionObject[option.FIELD],
        id:
          option.KEY === "user_ids"
            ? optionObject.user_id
            : optionObject[option.KEY]
      });
    });
  makeOptionsObject.selectedOptions = [];
  makeOptionsObject.type = option.TYPE;
  return makeOptionsObject;
}

export function makeAdvanceSearchOptionsArrayTemp(
  advanceOptionsSets,
  options,
  name,
  optionKey,
  filterKey
) {
  const makeOptionsObject = {};
  makeOptionsObject.name = name;
  makeOptionsObject.key = optionKey;
  for (const val of advanceOptionsSets) {
    if (val.key === optionKey) {
      return null;
    }
  }
  makeOptionsObject.options = [];
  options.map(optionObject => {
    makeOptionsObject.options.push({
      label: optionObject[filterKey],
      value: optionObject[optionKey]
    });
  });
  makeOptionsObject.selectedOptions = [];
  return makeOptionsObject;
}

/**
 * To get toast type
 * @param apiResponse
 */
export function getToastTypeForResponse(apiResponse) {
  switch (apiResponse.status) {
    case constant.HTTP_STATUS.OK:
    case constant.HTTP_STATUS.CREATED:
    case constant.HTTP_STATUS.SUCCESS:
      return constant.SPOG_TOAST_TYPES.SUCCESS;
    default:
      return constant.SPOG_TOAST_TYPES.ERROR;
  }
}

/**
 * To get key value pairs
 * @param {object} data
 * @param {string} keyParam
 * @param {string} valueParam
 */
export function dropdownValues(data, keyParam, valueParam) {
  let dropdownData = [];
  if (data && data.length > 0)
    dropdownData = data.map(option => ({
      key: option[keyParam],
      value: option[valueParam]
    }));
  return dropdownData;
}

/**
 * To get key value pairs for multi select
 * @param {object} data
 * @param {string} keyParam
 * @param {string} valueParam
 */
export function multiDropdownValues(data, keyParam, valueParam) {
  const dropdownData = Object.assign([], data);
  return dropdownData.map(option => {
    const test = {};
    test.value = option[keyParam];
    test.label = option[valueParam];
    return test;
  });
}

/**
 * To convet timestamp to given format
 * @param {number} timestamp
 * @param {string} format
 */
export function formatDate(timestamp, dateFormat) {
  const date = moment(timestamp).format(dateFormat);
  return date;
}

/* **
 * To get colour after adding opacity
 * @param hex
 * @param opacity
 * @returns {string}
 */
export function addOpacityToHex(hex, opacity) {
  const bigint = parseInt(hex.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}${(opacity * 255)
    .toString(16)
    .substring(0, 2)}`;
}

/**
 * To calculate number of days between two dates
 * @param firstDate
 * @param secondDate
 * @returns {number}
 */
export function getNumberOfDays(firstDate, secondDate) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  firstDate.setHours(0, 0, 0);
  secondDate.setHours(0, 0, 0);

  return Math.round(
    Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
  );
}

/**
 * to calculate the visual text length in px
 */
export function calculateTextLength(text, fontFamily, fontSize) {
  const ruler = document.getElementById("ruler");
  ruler.style.fontFamily = fontFamily || constant.DEFAULT_FONT.FAMILY;
  ruler.style.fontSize = fontSize || constant.DEFAULT_FONT.SIZE;
  ruler.innerHTML = text || "";
  return ruler.offsetWidth;
}

/**
 * generate the dropdown options in the required format
 *  @param options menu options array
 *  @param savedSeach saved search obj
 */
export function generateOptions(options, savedSearch) {
  const searchOptions = _.cloneDeep(options);
  if (Array.isArray(searchOptions)) {
    searchOptions.forEach(option => {
      const { key } = option;
      const { type } = option;

      if (savedSearch.hasOwnProperty(key)) {
        switch (type) {
          case "date-picker":
          case "date-range-picker":
            option.selectedOptions = { ...savedSearch[key] };
            break;

          case "single-select":
            {
              option.selectedOptions = [];
              //  loop over the array
              const selectedOpt = option.options.filter(opt => {
                const tempValue = opt.id ? opt.id : opt.value;
                return tempValue === savedSearch[key].toString();
              })[0];
              selectedOpt && option.selectedOptions.push(selectedOpt);
            }
            break;
          case "sub_organization_id":
            if (
              savedSearch.sub_organization_id &&
              Array.isArray(savedSearch.sub_organization_id)
            ) {
              const selectedOpt = option.options.filter(opt =>
                savedSearch.sub_organization_id.includes(opt.id)
              );
              option.selectedOptions = option.selectedOptions.concat(
                selectedOpt && selectedOpt.length > 0 ? selectedOpt : []
              );
            }
            break;
          default:
            // eslint-disable-next-line no-lone-blocks
            {
              option.selectedOptions = [];
              if (savedSearch[key] && !Array.isArray(savedSearch[key])) {
                const selectedOption = savedSearch[key].toString();
                const selectedOpt = option.options.filter(opt => {
                  if (
                    option.key === VSB_FILTER_ATTRIBUTES.SOURCE_STATE &&
                    opt.value.includes(VSB_IDENTIFIERS[option.key])
                  ) {
                    return opt.value.replace(VSB_IDENTIFIERS[option.key], "");
                  }
                  const tempValue = opt.id ? opt.id : opt.value;
                  return tempValue === selectedOption;
                });
                option.selectedOptions.push(
                  selectedOpt && selectedOpt.length > 0 ? selectedOpt[0] : []
                );
              } else {
                savedSearch[key] &&
                  savedSearch[key].forEach(selectedOption => {
                    const selectedOpt = option.options.filter(opt => {
                      if (
                        option.key === VSB_FILTER_ATTRIBUTES.SOURCE_STATE &&
                        opt.value.includes(VSB_IDENTIFIERS[option.key])
                      ) {
                        opt.value = opt.value.replace(
                          VSB_IDENTIFIERS[option.key],
                          ""
                        );
                      }
                      const tempValue = opt.id ? opt.id : opt.value;
                      return tempValue === selectedOption;
                    });
                    option.selectedOptions.push(
                      selectedOpt && selectedOpt.length > 0
                        ? selectedOpt[0]
                        : []
                    );
                  });
              }
            }
            break;
        }
      }
    });
    return searchOptions;
  }
}

/**
 *
 * @param navigation : navigation condition for type base
 * @returns {string}
 */
export function getTypeByNavigation(navigation) {
  let filterType;

  switch (navigation) {
    case constant.SECONDARY_NAVIGATION_TYPE.PHYSICAL_MACHINES:
      filterType = "machine";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_MACHINES:
      filterType = "agentless_vm";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.UNC_NFS_PATHS:
      filterType = "unc_nfs_paths";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.MACHINES:
      filterType = "machine";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBYS:
      filterType = "virtual_standby";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.VIRTUAL_STANDBY:
      filterType = "virtual_standbys";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.INSTANT_VMS:
      filterType = "instant_vm";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.SHARE_POINT:
      filterType = "share_point";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.OFFICE_365:
      filterType = "office_365";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.CLOUD_VOLUMES:
      filterType = "cloud_direct_volume";
      break;
    case constant.SECONDARY_NAVIGATION_TYPE.DEDUPE_STORE:
      filterType = "cloud_hybrid_store";
      break;
    default:
      filterType = navigation;
  }

  return filterType;
}

/**
 * To convert time value to hours and minutes
 * @param timeValue
 * @returns {{hours: number | *, minutes: *|string}}
 */
export function getTimeValue(timeValue) {
  let timesArr = ["00", "00"];
  let hours;

  if (timeValue.time) {
    timesArr = timeValue.time.split(":");
  }
  hours = parseInt(timesArr[0]);

  if (timeValue.AMPM === "PM") hours += 12;

  return {
    hours,
    minutes: timesArr[1]
  };
}

export function getTimeString(hours, minutes) {
  return `${_.padStart(hours, 2, "0")}:${_.padStart(minutes, 2, "0")}`;
}

/**
 * @returns {string}
 */
export function generateUUID() {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

/**
 * Get columns for a grid
 * @returns {Array}
 */
export const getColumnDataOfGrid = (masterColumnData, filterType) => {
  let columnData = [];
  const columnDataByType = masterColumnData || [];
  columnDataByType.forEach(obj => {
    if (obj.name === filterType) {
      columnData = obj.columnData;
    }
  });
  return columnData;
};

/**
 *
 * @param obj : current object check emty
 * @returns {boolean}
 */
export function isEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

/**
 *
 * @param queryStringToAppend : current query string append and made by Available search options for advanced search
 * @param sortedData : sort array info with field name and desc|asc params
 * @returns {string}
 */
export function getQueryStringFromSortedData(queryStringToAppend, sortedData) {
  let sortedString = "";
  if (sortedData.length > 0) {
    sortedString = sortedData[0].desc
      ? `sort=-${sortedData[0].id}`
      : `sort=${sortedData[0].id}`;
  }
  let queryString = "";
  if (queryStringToAppend !== "" && sortedString !== "") {
    queryString = `${queryStringToAppend}&${sortedString}`;
  } else if (sortedString !== "") {
    queryString = sortedString;
  } else {
    queryString = queryStringToAppend;
  }

  return queryString;
}

/**
 * This function will automatically convert bytes value into the best fit from the mentioned sizes and retruns the converted value along with the size extention.
 * @param bytesValue : pass bytes value to convert it into the below-mentioned sizes automatically.
 * @available_data_sizes : ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
 * @returns {string}
 */
export function byteConversion(bytesValue, isRound = true, intl = null) {
  if (!isNaN(bytesValue)) {
    const dataSizeUnits = [
      "bytes",
      "KB",
      "MB",
      "GB",
      "TB",
      "PB",
      "EB",
      "ZB",
      "YB"
    ];
    let sizeToReturn = 0;
    let bytes = bytesValue;
    let index = 0;
    for (index = 0; index < dataSizeUnits.length; index += 1) {
      if (bytes < 1024) {
        break;
      }
      bytes /= 1024;
    }
    sizeToReturn = isRound
      ? bytes.toFixed(2)
      : bytes.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
    return `${sizeToReturn} ${
      intl
        ? intl.formatMessage({
            id: dataSizeUnits[index].toLowerCase(),
            defaultMessage: dataSizeUnits[index]
          })
        : dataSizeUnits[index]
    }`;
  }
}

/**
 *
 * @param a : data in bytes
 * @param b : up to decimal points
 * @returns {string}
 */
export function formatBytes(a, b) {
  if (!a) {
    return `0 GB`;
  }
  if (a === 0) return "0 GB";
  const c = 1024;
  const d = b || 2;
  const e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const f = Math.floor(Math.log(a) / Math.log(c));
  return `${parseFloat((a / Math.pow(c, f)).toFixed(d))} ${e[f]}`;
}

export function sqlComponentSize(databaseList, componentName) {
  const item = databaseList.find(list => {
    if (componentName === list.name) {
      return list;
    }
  });
  return (item && item.size) || 0;
}

export function sqlComponentPitEnabled(databaseList, componentName) {
  const item = databaseList.find(list => {
    if (componentName === list.name) {
      return list;
    }
  });
  return (item && item.pit_enabled) || false;
}

export function sqlComponentStartTimePoint(databaseList, componentName) {
  const item = databaseList.find(list => {
    if (componentName === list.name) {
      return list;
    }
  });
  return (item && item.start_time_point) || "";
}

export function sqlComponentEndTimePoint(databaseList, componentName) {
  const item = databaseList.find(list => {
    if (componentName === list.name) {
      return list;
    }
  });
  return (item && item.end_time_point) || "";
}

export function sqlComponentTimePointCount(databaseList, componentName) {
  const item = databaseList.find(list => {
    if (componentName === list.name) {
      return list;
    }
  });
  return (item && item.time_point_count) || 0;
}

export function formatBytesToGBTBPBDecimal(a) {
  if (!a) {
    return `0 GB`;
  }
  if (a === 0) return "0 GB";
  a /= 1024 * 1024 * 1024; // min value is GB
  if (a / 1024 > 1) {
    a /= 1024;
    if (a / 1024 > 1) {
      a /= 1024;
      return a % 1 === 0 ? `${a} PB` : `${a.toFixed(2)} PB`;
    }
    return a % 1 === 0 ? `${a} TB` : `${a.toFixed(2)} TB`;
  }
  return a % 1 === 0 ? `${a} GB` : `${a.toFixed(2)} GB`;
}
export function formatBytesToGBDecimal(a) {
  if (!a) {
    return `0 GB`;
  }
  if (a === 0) return "0 GB";
  a /= 1024 * 1024 * 1024;
  return a % 1 === 0 ? `${a} GB` : `${a.toFixed(2)} GB`;
}

export function formatBytesToGBWithoutDecimal(a) {
  if (!a) {
    return `0 GB`;
  }
  if (a === 0) return "0 GB";
  a = Math.floor(a / (1024 * 1024 * 1024));
  return a % 1 === 0 ? `${a} GB` : `${a.toFixed(2)} GB`;
}

export function formatBytesToGBTBPB(a) {
  if (!a) return "0 GB";
  if (a === 0) return "0 GB";
  a /= 1024 * 1024 * 1024; // min value in the dropdown is GB
  const b = formatBytesToGBTBPBHelper(a, 0, true);
  return b;
}
/*
  the function formatBytesToGBTBPB takes bytes as input (a)
  and returns a string formating the input into either GB , TB or PB as 
  datatype of int
*/
function formatBytesToGBTBPBHelper(a, count, isValid) {
  if (count === 2) return `${a} PB`;
  if (a / 1024 >= 1 && isValid) {
    if (a % 1024 > 0) return formatBytesToGBTBPBHelper(a, count, false);
    const temp = a;
    a /= 1024;
    if (a < 2) {
      if (a === 1) return formatBytesToGBTBPBHelper(a, count + 1, isValid);
      return formatBytesToGBTBPBHelper(temp, count, false);
    }
    return formatBytesToGBTBPBHelper(a, count + 1, isValid);
  }
  if (count === 0) return `${a} GB`;
  if (count === 1) return `${a} TB`;
}

/**
 * Convert bytes to TB
 * @param a
 */
export const formatBytesToTB = (a, b = 2) => {
  if (a === 0) {
    return "0 TB";
  }
  const res = (a / 1099511627776).toFixed(3);
  return `${res.substring(0, res.length - 1)} TB`;
};
export const sortByOrder = (a, b, columnId) => {
  const rowOne =
    typeof a.values[columnId] !== "object"
      ? a.values[columnId]?.toLowerCase()
      : a.values[columnId]?.props?.children?.[0]?.toLowerCase();
  const rowTwo =
    typeof b.values[columnId] !== "object"
      ? b.values[columnId]?.toLowerCase()
      : b.values[columnId]?.props?.children?.[0]?.toLowerCase();
  if (rowOne < rowTwo) {
    return -1;
  }
  if (rowOne > rowTwo) {
    return 1;
  }
  return 0;
};

/**
 * Convert bytes to GB
 * @param a
 */
export const formatBytesToGB = a => {
  if (a === 0) {
    return 0;
  }
  return Math.floor(a / 1073741824);
};

export const formatBytesToMB = a => {
  if (a === 0) {
    return 0;
  }
  return Math.floor(a / 1048576);
};

/**
 *
 * @param val : data in MB/GB/TB/PB
 * @param unit : up to decimal points
 * @returns {Number}
 */
export function getConvertedIntoKb(val, unit) {
  let kbVal = 0;
  switch (unit) {
    case "MB":
      kbVal = 1024 * val;
      break;
    case "GB":
      kbVal = 1024 * 1024 * val;
      break;
    case "TB":
      kbVal = 1024 * 1024 * 1024 * val;
      break;
    case "PB":
      kbVal = 1024 * 1024 * 1024 * 1024 * val;
      break;
    default:
      kbVal = 1024 * 1024 * val;
  }
  return kbVal;
}

export function convertArrayOfObjectsToCSV(args, headerObj, fileName) {
  let result;
  let ctr;
  const data = args || null;
  if (data == null || !data.length) {
    return null;
  }
  const columnDelimiter = args.columnDelimiter || ",";
  const lineDelimiter = args.lineDelimiter || "\n";
  const keys = Object.keys(data[0]);
  result = "";
  result += headerObj.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(item => {
    ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr += 1;
    });
    result += lineDelimiter;
  });
  let csv = result;
  if (!csv) return;
  const filename = `${fileName}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }
  const csvdata = encodeURI(csv);
  const link = window.document.createElement("a");
  link.setAttribute("href", csvdata);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  eventFire(link, "click");
  document.body.removeChild(link);
}

export function convertToPDF(
  node,
  childNode,
  fileName,
  nodeCss,
  nodedefaultCss
) {
  node.setAttribute("style", nodeCss);
  domtoimage
    .toPng(childNode)
    .then(dataUrl => {
      const img = new Image();
      img.src = dataUrl;
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF("p", "mm", [500, 1000]);
      pdf.addImage(img, "PNG", 10, 10, img.width, img.height);
      pdf.save(fileName);
      node.setAttribute("style", nodedefaultCss);
    })
    .catch(error => {
      window.console.error("oops, something went wrong!", error);
      node.setAttribute("style", nodedefaultCss);
    });
}

export function exportAsPdf(id, filter) {
  const node = document.getElementById(id);
  const childNode = document.getElementById("export-container-pdf");
  const nodeCss = "width:1700px; !important;height: 100%;";
  const nodedefaultCss = "width: 100%; height: 100%; position: relative;";
  convertToPDF(node, childNode, filter, nodeCss, nodedefaultCss);
}

/**
 * to store data into txt file and download
 * @param {*} data
 * @param {*} type
 * @param {*} fileName
 */
export function storeDataToTxt(data, type, fileName) {
  let link;
  let url;
  const file = new Blob([data], { type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, fileName);
  else {
    // Other browsers
    link = document.createElement("a");
    url = URL.createObjectURL(file);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export function getBrowserLocale() {
  let lang;
  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0]; // latest versions of Chrome and Firefox set this correctly
  } else if (navigator.userLanguage) {
    lang = navigator.userLanguage; // IE only
  } else {
    lang = navigator.language; // latest versions of Chrome, Firefox, and Safari set this correctly
  }
  return lang;
}

export const getLocaleDate = (timestamp, format, convertTS = true, lang) => {
  const state = JSON.parse(localStorage.getItem("state"));
  const userLocale = state?.login?.locale;
  const locale = lang || userLocale || getBrowserLocale();
  locale && moment.locale(locale);
  return moment.unix(timestamp).format(format || "lll");
};

export const getDate = (timestamp, format /* , convertTS = true */) =>
  moment(timestamp).format(format || "lll");
/**
 * add particular node from file browser data
 */
export function addNode(treeData, rowInfo, NEW_NODE) {
  const { path } = rowInfo;
  const parentNode = getNodeAtPath({
    treeData,
    path,
    getNodeKey: ({ treeIndex }) => treeIndex,
    ignoreCollapsed: true
  });
  const getNodeKey = ({ node: object, treeIndex: number }) => number;
  let parentKey = getNodeKey(parentNode);
  if (parentKey === -1) {
    parentKey = null;
  }
  const newTree = addNodeUnderParent({
    treeData,
    newNode: NEW_NODE,
    expandParent: true,
    parentKey,
    getNodeKey: ({ treeIndex }) => treeIndex
  });

  return newTree.treeData;
}

/**
 * @param {*} isd
 * @param {*} number
 * returns concatenation of isd and phone number
 */
export function setPhoneNumber(isd, number) {
  if (isd && number) {
    return `${isd}${number}`;
  }
  if (number) {
    return number;
  }
  return "";
}

/**
 * split phone by isd and phone number
 */
export function splitPhoneNumber(number) {
  const isdObj = constant.ISD_CODES.find(
    isd => number.indexOf(isd.callingCode) === 0
  );

  if (isdObj !== -1 && isdObj != null) {
    const isd = isdObj.callingCode;
    const phoneNumber = number.substring(isd.length);

    if (phoneNumber.includes("+")) {
      return splitPhoneNumber(phoneNumber);
    }

    return {
      phoneNumber,
      isd
    };
  }

  return {
    phoneNumber: number,
    isd: ""
  };
}

/**
 * remove particular node from file browser data
 */
export function removeNode(treeData, rowInfo) {
  const { path } = rowInfo;
  const newTreeData = removeNodeAtPath({
    treeData,
    path, // You can use path from here
    getNodeKey: ({ node: TreeNode, treeIndex: number }) => number,
    ignoreCollapsed: false
  });
  return newTreeData;
}

/**
 * Generate color schema
 * @param {*} data
 * @param {*} key
 */
export function colorSchema(data, colors, key = "name") {
  const colorArray = [];
  if (data && Array.isArray(data)) {
    if (colors && colors !== undefined) {
      const tempData = _.uniqBy(data, key);
      tempData.map(obj => {
        colorArray.push(
          colors[
            colors[obj[key] && obj[key].toLowerCase()] !== undefined
              ? obj[key].toLowerCase()
              : obj.newStack
          ]
        );
      });
      return colorArray;
    }
    return [];
  }
  // eslint-disable-next-line guard-for-in, no-shadow
  for (const key in colors) {
    colorArray.push(colors[key].toLowerCase());
  }
  return colorArray;
}

/**
 * Generate legend data
 * @param {*} data
 * @param {*} key
 */
export function legends(data, key) {
  let dataArray;
  if (data && Array.isArray(data)) {
    dataArray = _.uniqBy(data, key);
    return dataArray;
  }
}
/**
 * convert data for chart
 * @param {*} data
 * @param {*} key
 */
export function convertedDataForChart(data, key, type) {
  let convertedDataSet = [];
  let axisLabel = "";

  if (data && data.length > 0)
    switch (key) {
      case constant.CHART_DATA_TYPE.DURATION:
        axisLabel = "Minutes";
        convertedDataSet = data.map(item => {
          item.value = getDurationInMinutes(item.value);
          return item;
        });
        break;

      case constant.CHART_DATA_TYPE.BYTES:
        axisLabel = "Gigabytes";
        convertedDataSet = data.map(item => {
          item.value = formatBytesToGB(item.value);
          // eslint-disable-next-line no-void
          !item.label ? (item.label = item.name) : void 0;
          item.name = item.id;

          // }
          // first check for ts field, and otherwise, use date as a failback.
          if (item.ts) {
            item.date = moment
              .utc(item.ts * 1000)
              .local()
              .toDate();
          } else if (item.date && typeof item.date === "string") {
            if (item.date.indexOf("+") !== -1) {
              item.date = item.date.split("+")[0];
            } else if (item.date.indexOf(",")) {
              const date = item.date.split(",");
              if (date.length > 3) {
                const time = date[3].split(":");
                item.date = new Date(
                  date[0],
                  date[1] - 1,
                  date[2],
                  time[0],
                  time[1],
                  time[2]
                );
              } else if (date.length === 2) {
                item.date = new Date(date[0], date[1] - 1, 1);
              } else {
                item.date = new Date(date[0], date[1] - 1, date[2]);
              }
            }
          }
          return item;
        });
        break;
      case constant.CHART_DATA_TYPE.DT_BYTES:
        axisLabel = "Gigabytes";
        convertedDataSet = data.map(item => {
          item.value = formatBytesToGB(item.value);

          // eslint-disable-next-line no-unused-expressions
          !item.label ? (item.label = item.name) : undefined;
          // first check for ts field, and otherwise, use date as a failback.
          if (item.ts) {
            item.date = moment
              .utc(item.ts * 1000)
              .local()
              .toDate();
          } else if (item.date && typeof item.date === "string") {
            if (item.date.indexOf("+") !== -1) {
              item.date = item.date.split("+")[0];
            } else if (item.date.indexOf(",")) {
              const date = item.date.split(",");
              if (date.length > 3) {
                const time = date[3].split(":");
                item.date = new Date(
                  date[0],
                  date[1] - 1,
                  date[2],
                  time[0],
                  time[1],
                  time[2]
                );
              } else if (date.length === 2) {
                item.date = new Date(date[0], date[1] - 1, 1);
              } else {
                item.date = new Date(date[0], date[1] - 1, date[2]);
              }
            }
          }
          return item;
        });
        break;

      default:
        break;
    }

  return {
    data: convertedDataSet,
    yAxisLabel: axisLabel
  };
}

/**
 * Get appropriate string from constants
 * @param {*} data
 * @param {*} key
 */
export function translateTextFromConstants(data, key) {
  let tempData = [];
  if (!tempData.length) {
    data.map(obj => {
      if (obj[key]) {
        obj.newStack = obj[key];
        obj[key] =
          constant.ConvertText[
            constant.ConvertText[obj[key].toLowerCase()]
              ? obj[key].toLowerCase()
              : obj.newStack
          ];
        return obj;
      }
    });
    tempData = [...data];
    return data;
  }
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function getDurationInMinutes(time) {
  if (!time || time === 0) {
    return 0;
  }
  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = time % 60;

  const totalmins = hrs * 60 + mins + secs / 60;
  return isFloat(totalmins) ? totalmins.toFixed(2) : totalmins;
}

export function getDuration(time, intl = null) {
  if (!time || time === 0) {
    return `0 ${
      intl
        ? intl.formatMessage({
            id: "seconds",
            defaultMessage: "Seconds"
          })
        : "Seconds"
    }`;
  }
  let hrs = ~~(time / 3600);
  let mins = ~~((time % 3600) / 60);
  let secs = ~~(time % 60);
  let ret = "";
  hrs = isFloat(hrs) ? hrs.toFixed(2) : hrs;
  mins = isFloat(mins) ? mins.toFixed(2) : mins;
  secs = isFloat(secs) ? secs.toFixed(2) : secs;
  if (hrs > 0) {
    if (hrs < 2) {
      ret += `${hrs} hour`;
    } else {
      ret += `${hrs} hours`;
    }
  }
  if (mins > 0) {
    if (mins < 2) {
      ret += ` ${mins} minute`;
    } else {
      ret += ` ${mins} minutes`;
    }
  }
  if (secs > 0) {
    if (secs < 2) {
      ret += ` ${secs} second`;
    } else {
      ret += ` ${secs} seconds`;
    }
  }
  if (intl) {
    return ret
      .split(" ")
      .map(word =>
        intl.formatMessage({
          id: word !== "" ? word : "emptyString",
          defaultMessage: word
        })
      )
      .join(" ");
  }
  return ret;
}

export function eventFire(el, etype) {
  if (el[etype] && typeof el[etype] === "function")
    switch (etype) {
      case "click":
        el.click((event, anchor) => {
          window.open(anchor.href, anchor.target, "");
          event.preventDefault();
          return false;
        });
        break;

      default:
        break;
    }
  else if (el.fireEvent) el.fireEvent(`on${etype}`);
  else {
    const evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

export function prepareGridCriteria(payload, reducerData) {
  let query = "";
  // default pagination params
  let gridCurrentPage = reducerData.gridCurrentPage
    ? reducerData.gridCurrentPage
    : 1;
  let gridPageSize = reducerData.gridPageSize ? reducerData.gridPageSize : 25;

  if (payload.data) {
    // if pagination is used, make use of pagination params
    const data = payload.data;
    if (data.gridCurrentPage && data.gridPageSize) {
      gridPageSize = data.gridPageSize;
      gridCurrentPage = data.gridCurrentPage;
    }
  }
  query += `page=${gridCurrentPage}&page_size=${gridPageSize}`;

  if (reducerData) {
    if (reducerData.searchKey && reducerData.searchText) {
      query = `${query}&${reducerData.searchKey}=${reducerData.searchText}`;
    }
    if (
      reducerData.sortedColumns &&
      Array.isArray(reducerData.sortedColumns) &&
      reducerData.sortedColumns.length
    ) {
      query = getQueryStringFromSortedData(query, reducerData.sortedColumns);
    }
  }
  return query;
}

export function timeToDisplay(hour, minute, hasSuffix = true) {
  const h =
    hour === 0
      ? "12"
      : hour > 12
      ? hour - 12 < 10
        ? `0${hour - 12}`
        : `${hour - 12}`
      : hour < 10
      ? `0${hour}`
      : `${hour}`;
  const m = minute < 10 ? `0${minute}` : `${minute}`;
  const suffix = hour < 12 ? "AM" : "PM";
  return hasSuffix ? `${h}:${m} ${suffix}` : `${h}:${m}`;
}

export function timeToDisplay24Hour(hour, minute) {
  return `${hour}:${minute}`;
}

export function getScheduleFromCrontab(schedule) {
  if (!schedule) return {};

  if (schedule.length > 0) {
    const arrSchedule = schedule.split(" ");

    let frequency = "";

    if (arrSchedule[5] === "?") {
      frequency = "monthly";
    } else if (arrSchedule[3] === "?") {
      if (arrSchedule[5] === "*") {
        frequency = "daily";
      } else {
        frequency = "weekly";
      }
    }

    let second = parseInt(arrSchedule[0], 10);
    if (isNaN(second)) second = 0;

    let minute = parseInt(arrSchedule[1], 10);
    if (isNaN(minute)) minute = 0;

    let hour = parseInt(arrSchedule[2], 10);
    if (isNaN(hour)) hour = 0;

    return {
      second,
      minute,
      hour,
      frequency
    };
  }
}

export function getScheduleFromUTCCrontab(schedule) {
  if (!schedule) return {};
  if (schedule.length > 0) {
    schedule = schedule.trim();
    const arrSchedule = schedule.split(" ");

    let frequency = "";

    if (arrSchedule[5] === "?") {
      frequency = "monthly";
    } else if (arrSchedule[3] === "?") {
      if (arrSchedule[5] === "*") {
        frequency = "daily";
      } else {
        frequency = "weekly";
      }
    }

    let second = parseInt(arrSchedule[0], 10);
    if (isNaN(second)) second = 0;

    let minute = parseInt(arrSchedule[1], 10);
    if (isNaN(minute)) minute = 0;

    let hour = parseInt(arrSchedule[2], 10);
    if (isNaN(hour)) hour = 0;

    [hour, minute] = moment
      .utc({ hour, minute })
      .local()
      .format("HH:mm")
      .split(":");
    return {
      second,
      minute,
      hour,
      frequency
    };
  }
}

export function stripslashes(str) {
  return str.replace(/\\/g, "");
}

export function replaceAll(string, spitBy, replacement) {
  return string.split(spitBy).join(replacement);
}

export function getFilename(str) {
  if (str) {
    str = str.trim();
    if (str && str.length > 0 && str[str.length - 1] !== ";") str += ";";
    return str.match(/(?:filename=)(.+?)(?:(;){1})/)[1];
  }
  return "";
}

export function getAdvancedSearchNameLabel(filter) {
  switch (filter) {
    case constant.SECONDARY_NAVIGATION_TYPE.USER_ACCOUNTS:
      return "name_email_contains";

    case constant.SECONDARY_NAVIGATION_TYPE.LOG:
    case constant.SECONDARY_NAVIGATION_TYPE.SOURCE_LOG:
      return "msg_contains";

    default:
      return "name_contains";
  }
}

export function generateCron(formValues) {
  let generatedCronTab = null;
  const splitedTime = formValues.schedule && formValues.schedule.split(":");
  splitedTime[1] =
    splitedTime[1].length === 2 && splitedTime[1].substr(0, 1) === "0"
      ? splitedTime[1].substr(1, 1)
      : splitedTime[1];
  splitedTime[0] =
    splitedTime[0].length === 2 && splitedTime[0].substr(0, 1) === "0"
      ? splitedTime[0].substr(1, 1)
      : splitedTime[0];

  switch (formValues.schedule_frequency) {
    case "daily":
      generatedCronTab = `0 ${splitedTime[1]} ${splitedTime[0]} ? * *`;
      break;
    case "weekly":
      generatedCronTab = `0 ${splitedTime[1]} ${
        splitedTime[0]
      } ? * ${moment().day()}`;
      break;
    case "monthly":
      generatedCronTab = `0 ${splitedTime[1]} ${
        splitedTime[0]
      } ${moment().date()} * ?`;
      break;
    default:
      generatedCronTab = ` 0 ${splitedTime[1]} ${splitedTime[0]} ? * *`;
  }
  return generatedCronTab;
}

export function generateUTCCron(formValues) {
  let generatedCronTab = null;

  let splitedTime = formValues.schedule && formValues.schedule.split(":");
  const utcSchedule = moment({ hour: splitedTime[0], minute: splitedTime[1] })
    .utc()
    .format("HH:mm");
  splitedTime = utcSchedule && utcSchedule.split(":");
  splitedTime[1] =
    splitedTime[1].length === 2 && splitedTime[1].substr(0, 1) === "0"
      ? splitedTime[1].substr(1, 1)
      : splitedTime[1];
  splitedTime[0] =
    splitedTime[0].length === 2 && splitedTime[0].substr(0, 1) === "0"
      ? splitedTime[0].substr(1, 1)
      : splitedTime[0];

  switch (formValues.schedule_frequency) {
    case "daily":
      generatedCronTab = `0 ${splitedTime[1]} ${splitedTime[0]} ? * *`;
      break;
    case "weekly":
      generatedCronTab = `0 ${splitedTime[1]} ${splitedTime[0]} ? * ${formValues.schedule_day}`;
      break;
    case "monthly":
      generatedCronTab = `0 ${splitedTime[1]} ${splitedTime[0]} ${formValues.schedule_date} * ?`;
      break;
    default:
      generatedCronTab = ` 0 ${splitedTime[1]} ${splitedTime[0]} ? * *`;
  }
  return generatedCronTab;
}

export function getChildrensWithoutEmptyObject(n, volGuid) {
  if (Array.isArray(n)) {
    n.map(subN => {
      if (subN.vol_guid === volGuid) {
        if (
          Array.isArray(subN) &&
          (subN.children === undefined || subN.children.length === 0)
        ) {
          return subN;
        }
        if (
          !Array.isArray(subN) &&
          typeof subN === "object" &&
          (subN.children === undefined || subN.children.length === 0)
        ) {
          return subN;
        }
        // if (subN.expanded) {
        subN.children = subN.children.filter(
          value => Object.keys(value).length !== 0
        );
        subN.children.forEach(c => {
          getChildrensWithoutEmptyObject(c);
        });
        // }
      }
      return subN;
    });
  } else {
    if (
      !Array.isArray(n) &&
      typeof n === "object" &&
      (n.children === undefined || n.children.length === 0)
    ) {
      return n;
    }
    if (n.expanded) {
      n.children = n.children.filter(value => Object.keys(value).length !== 0);
      n.children.forEach(c => {
        getChildrensWithoutEmptyObject(c);
      });
    }
  }
  return n;
}

/*
 * compare values between two arrays
 * firstKey: key of first array to be check
 * secondKey: key of second array to be check
 */
export function comparerArray(otherArray, firstKey, secondKey) {
  return current =>
    otherArray.filter(other => other[secondKey] === current[firstKey])
      .length === 0;
}

/**
 * Function to fetch timezone in format of GMT(+/-) from offset
 */
export function getTimezone() {
  let offset = moment().utcOffset();
  const flagPosOffset = offset >= 0;
  let h = 0;
  let m = 0;
  offset = Math.abs(offset);
  h = parseInt(offset / 60, 10);
  m = offset % 60;
  if (!h && !m) return "GMT";
  if (m) {
    m = m < 10 ? `0${m}` : `${m}`;
    if (flagPosOffset) return `GMT+${h}.${m}`;
    return `GMT-${h}.${m}`;
  }
  if (flagPosOffset) return `GMT+${h}`;
  return `GMT-${h}`;
}

export function getCustomTimeResolution(startTs, endTs) {
  const a = moment.unix(startTs);
  const b = moment.unix(endTs);

  const h = b.diff(a, "hours", true);
  const m = b.diff(a, "months", true);
  const y = b.diff(a, "years", true);

  if (h <= 24) return "hour";
  if (m <= 3) return "day";
  if (y <= 1) return "month";
  return "year";
}

export const convertUTCtimestamp = (
  timestamp = moment.unix() /* ,
  convertToLocale = true */
) => timestamp; // no need to convert

export function isDateDifferenceLessThan(dateOption, key, value) {
  if (
    dateOption.type &&
    dateOption.type === constant.DATE_RANGE_OPTIONS.CUSTOM
  ) {
    return (
      moment
        .unix(dateOption.end_ts)
        .diff(moment.unix(dateOption.start_ts), key, true) <= value
    );
  }
  return false;
}

export const manageOrganizationColumnDisplay = (
  columnData,
  userRole,
  isImpersonationView,
  enableSort = false
) => {
  const orgColumn = {
    identifier: "organization",
    show: true,
    sort: enableSort
  };
  const hasOrgNameColumn =
    _.findIndex(columnData, item => item.identifier === orgColumn.identifier) >=
    0;
  if (
    userRole === "direct" ||
    userRole === "msp_child" ||
    (userRole === "msp" && isImpersonationView)
  ) {
    if (hasOrgNameColumn) {
      columnData = _.remove(
        columnData,
        item => item.identifier === orgColumn.identifier
      );
    }
  } else if (userRole === "msp" && !isImpersonationView) {
    if (!hasOrgNameColumn) {
      columnData.splice(3, 0, orgColumn);
    }
  }
  return columnData;
};

export const getMinimumSearchLength = locale => {
  if (locale === "ja") {
    return 1;
  }
  return 3;
};

export const getCountryOptions = locale =>
  locale === "ja"
    ? multiDropdownValues(constant.JA_ISD_CODES, "callingCode", "name")
    : multiDropdownValues(constant.ISD_CODES, "callingCode", "name").sort(
        (a, b) => {
          const labelA = a.label.toUpperCase();
          const labelB = b.label.toUpperCase();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }
      );

export const getDraasCapacity = () => {
  const dropdownData = [];
  Object.keys(constant.DRAAS_SKU).map(sku => {
    const test = {};
    test.value = constant.DRAAS_SKU[sku];
    test.keyValue = sku;
    test.label = constant.DRAAS_SKU[sku];
    dropdownData.push(test);
  });
  return dropdownData;
};

export const showWarningForPolicyWithLargeAmountOfVms = (policyType, sources) =>
  !!(policyType === POLICY_TYPES.HYPERVISOR && sources && sources.length >= 15);

/**
 * Takes all the selectedSavedSearch properties and makes a querystring
 * @param {*} selectedSavedSearch
 */
export const getQueryStringBySavedSearch = (
  selectedSavedSearch,
  queryString
) => {
  const curFilters = queryString.split("&").map(item => item.split("=")[0]);
  let updatedQueryString = "";
  if (selectedSavedSearch) {
    Object.keys(selectedSavedSearch).forEach(key => {
      if (
        key !== "filter_id" &&
        key !== "filter_name" &&
        curFilters.indexOf(key) === -1
      ) {
        if (selectedSavedSearch[key].length > 0) {
          if (updatedQueryString.length > 0) {
            updatedQueryString += "&";
          }
          updatedQueryString += `${key}=${encodeURIComponent(
            selectedSavedSearch[key].join("|")
          )}`;
        }
      }
    });
  }
  return updatedQueryString;
};

export const insertSelectedSavedSearchIntoAdvancedSearch = (
  selectedSavedSearch,
  advancedSavedSearch,
  userSearchData,
  selectedSavedSearchId,
  criteria = false
) => {
  if (
    selectedSavedSearchId &&
    Object.keys(selectedSavedSearch).length === 0 &&
    selectedSavedSearch.constructor === Object
  ) {
    selectedSavedSearch = userSearchData.filter(
      ele => ele.filter_id === selectedSavedSearchId
    )[0];
  }
  advancedSavedSearch.forEach(ele => {
    if (
      ele.key in selectedSavedSearch &&
      ele.selectedOptions &&
      selectedSavedSearch[ele.key]
    ) {
      if (constant.SEARCH_FILTERS_TYPE[ele.key] === "multiple") {
        if (
          ele.key ===
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.KEY &&
          constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE in
            selectedSavedSearch
        ) {
          selectedSavedSearch[
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE
          ].forEach((item, index) => {
            if (!item.id) {
              selectedSavedSearch[
                constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE
              ][index] = {
                id:
                  selectedSavedSearch[
                    constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE
                  ][index]
              };
            }
          });
        } else if (
          ele.key !==
          constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.KEY
        ) {
          selectedSavedSearch[ele.key].forEach((item, index) => {
            if (!item.id) {
              selectedSavedSearch[ele.key][index] = {
                id: selectedSavedSearch[ele.key][index]
              };
            }
          });
        }
      }
      if (
        ele.key ===
          constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.KEY &&
        constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE in
          selectedSavedSearch
      ) {
        ele.selectedOptions =
          selectedSavedSearch[
            constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.TYPE
          ];
      } else if (
        ele.key !== constant.ADVANCED_SEARCH_API_OPTIONS_KEYS.ORGANIZATIONS.KEY
      ) {
        ele.selectedOptions = selectedSavedSearch[ele.key];
      }
    }
  });
  let searchText = [];
  if (selectedSavedSearch) {
    searchText = Object.entries(selectedSavedSearch).filter(
      ele => Object.values(constant.SEARCH_TEXT_MAPPING).indexOf(ele[0]) > -1
    );
  }
  if (criteria) {
    return {
      advancedSearchOptions: advancedSavedSearch,
      searchText:
        searchText.length > 0 && searchText[0].length > 0
          ? searchText[0][1]
          : ""
    };
  }
  return advancedSavedSearch;
};

// eslint-disable-next-line arrow-body-style
export const isPageNav = (prevHistory, curHistory, route) => {
  return (
    ((curHistory.action === "PUSH" && prevHistory.action === "PUSH") ||
      (curHistory.action === "POP" && prevHistory.action === "POP")) &&
    curHistory.location.key === prevHistory.location.key &&
    curHistory.location.pathname === route
  );
};

export const getUpdateFilters = (
  selectedSavedSearch,
  advancedSearchOptions,
  userSearchData,
  selectedSavedSearchId,
  selectedOption,
  currentSearchOptions,
  searchTextName
) => {
  const criteria = insertSelectedSavedSearchIntoAdvancedSearch(
    selectedSavedSearch,
    advancedSearchOptions,
    userSearchData,
    selectedSavedSearchId,
    true
  );
  const searchText = criteria.savedsearch
    ? ""
    : selectedOption || criteria.searchText;
  const chartParameters = getChartParameters(
    criteria,
    currentSearchOptions,
    searchText,
    searchTextName
  );
  return chartParameters;
};

export const translateRoleIds = (
  roleId,
  organizationType,
  isImpersonationView,
  role = ""
) => {
  // eslint-disable-next-line default-case
  switch (role) {
    case "super_admin":
      roleId =
        organizationType === "msp"
          ? "configure.accessControl.userAccounts.user_role.msp_super_admin"
          : "configure.accessControl.userAccounts.user_role.super_admin";
      break;
    case "admin":
      roleId =
        organizationType === "msp"
          ? "configure.accessControl.userAccounts.user_role.msp_admin"
          : "configure.accessControl.userAccounts.user_role.admin";
      break;
    case "monitor":
      roleId =
        organizationType === "msp"
          ? "configure.accessControl.userAccounts.user_role.msp_monitor"
          : "configure.accessControl.userAccounts.user_role.monitor";
      break;
    case "recovery_admin":
      roleId =
        organizationType === "msp"
          ? "configure.accessControl.userAccounts.user_role.msp_recovery_admin"
          : "configure.accessControl.userAccounts.user_role.recovery_admin";
      break;
    case "account_admin":
      roleId =
        "configure.accessControl.userAccounts.user_role.msp_account_admin";
      break;
    case "account_monitor":
      roleId =
        "configure.accessControl.userAccounts.user_role.msp_account_monitor";
      break;
    case "account_recovery_admin":
      roleId =
        "configure.accessControl.userAccounts.user_role.msp_account_recovery_admin";
      break;
    case "tenant_monitor":
      roleId = "configure.accessControl.userAccounts.user_role.tenant_monitor";
      break;
    case "tenant_admin":
      roleId = "configure.accessControl.userAccounts.user_role.tenant_admin";
      break;
    case "tenant_recovery_admin":
      roleId =
        "configure.accessControl.userAccounts.user_role.tenant_recovery_admin";
      break;
  }
  return roleId;
};

export const getUserRolesName = (roleId, organizationType, userType) => {
  switch (roleId) {
    case "msp_admin":
      if (organizationType === "msp" && userType === "admin") {
        roleId = "msp_super_admin_role_name";
      } else if (organizationType === "msp" && userType === "user") {
        roleId = "msp_admin_role_name";
      }
      break;
    case "msp_monitor":
      if (organizationType === "msp" && userType === "user") {
        roleId = "msp_monitor_role_name";
      }
      break;
    case "msp_recovery_admin":
      if (organizationType === "msp" && userType === "user") {
        roleId = "msp_recovery_admin_role_name";
      }
      break;
    case "msp_account_monitor":
      if (organizationType === "msp" && userType === "user") {
        roleId = "msp_account_monitor_role_name";
      }
      break;
    case "msp_account_admin":
      if (organizationType === "msp" && userType === "user") {
        roleId = "msp_account_admin_role_name";
      }
      break;
    case "msp_account_recovery_admin":
      if (organizationType === "msp" && userType === "user") {
        roleId = "msp_account_recovery_admin_role_name";
      }
      break;
    case "direct_admin":
      if (organizationType === "msp_child" && userType === "external") {
        roleId = "msp_direct_admin_role_name";
      } else if (organizationType === "direct" && userType === "admin") {
        roleId = "direct_super_admin_role_name";
      } else if (organizationType === "direct" && userType === "user") {
        roleId = "direct_admin_role_name";
      }
      break;
    case "direct_recovery_admin":
      if (organizationType === "msp_child" && userType === "external") {
        roleId = "msp_direct_recovery_admin_role_name";
      } else if (organizationType === "direct" && userType === "user") {
        roleId = "direct_recovery_admin_role_name";
      }
      break;
    case "direct_monitor":
      if (organizationType === "msp_child" && userType === "external") {
        roleId = "msp_direct_monitor_role_name";
      } else if (organizationType === "direct" && userType === "user") {
        roleId = "direct_monitor_role_name";
      }
      break;
    default:
      return false;
  }
  return roleId;
};

export const getHelpLink = (locale, link = constant.DOCUMENT_LINK) => {
  switch (locale) {
    case "ja":
      return link.replace("/ENU/", "/JPN/");
    case "pt":
      return link.replace("/ENU/", "/PTB/");
    case "de":
      return link.replace("/ENU/", "/DEU/");
    case "fr":
      return link.replace("/ENU/", "/FRA/");
    case "it":
      return link.replace("/ENU/", "/ITA/");
    case "es":
      return link.replace("/ENU/", "/ESP/");
    default:
      return link;
  }
};

export const openHelpPageTextBtn = (intl, allHelpLinks, id) => {
  const { url } = (allHelpLinks &&
    allHelpLinks.links.find(
      link => link.name === id && link.language_type === intl
    )) || { url: getHelpLink(intl) };
  return url;
};
export const showFreeText = (intl = null) => {
  let freeText = "";
  freeText =
    intl?.formatMessage({
      id: "protect.destination.ds.free_text",
      defaultMessage: "Free"
    }) || "Free";

  return freeText;
};

export const evaluateSessionName = rp => {
  if (rp.sub_path.includes("\\")) {
    return rp.sub_path.substring(
      rp.sub_path.lastIndexOf("\\") + 1,
      rp.sub_path.length
    );
  }
  if (rp.sub_path.includes("/")) {
    return rp.sub_path.substring(
      rp.sub_path.lastIndexOf("/") + 1,
      rp.sub_path.length
    );
  }
  if (rp.sub_path.includes("VStore")) {
    return rp.sub_path.substring(6, rp.sub_path.length);
  }
  return rp.sub_path;
};

export const isWithinLast92Days = timestamp => {
  if (isNaN(timestamp)) {
    return true;
  }
  // 91 is mentioned to not include today while caculating
  return moment
    .utc("00:00", "HH:mm")
    .subtract(91, "days")
    .isBefore(moment.utc(timestamp));
};

/**
 * Handle organization name
 * Checking organization type and return the organization name base on that
 * @param {Object} organizationInfo
 * @param {String} menuItemKey
 * @returns {String}
 */
export const organizationNameHandler = (organizationInfo, menuItemKey) =>
  organizationInfo && organizationInfo.organization_name
    ? organizationInfo && organizationInfo.organization_name
    : menuItemKey;
