import _ from "lodash";
import uuidv4 from "uuid/v4";

import { createTreeConversionFunction } from "utils";
import { protectionType } from "../CreatePolicyConstants";

// constants
const START_TIME = "00:00";
const BACKUP_START_TIME = "22:00";
const END_TIME = "23:59";
const REPLICATION_START_TIME = "08:00";
const REPLICATION_END_TIME = "18:00";

export const EVERY_DAY_OF_WEEK = "0,1,2,3,4,5,6";
export const ADD_REMOTE_CONSOLE = "addRemoteConsole";
export const CREATE_CLOUD_ACCOUNT = "addCloudAccount";
export const ENCRYPTED_PASSWORD = "________";

export const POLICY_TYPES = {
  BAAS: "cloud_direct_baas",
  DRAAS: "cloud_direct_draas",
  HYBRID: "cloud_hybrid_replication",
  HYPERVISOR: "cloud_direct_hypervisor",
  UDP_AGENT_BASED_WINDOWS: "udp_agent_based_windows",
  UDP_AGENT_BASED_LINUX: "udp_agent_based_linux",
  UDP_UNC_NFC_BACKUP: "udp_files_on_unc",
  UDP_ORACLE_DATABASE: "udp_oracle_database",
  UDP_REPLICATE_FROM_REMOTE_RPS: "copy_from_remotely_managed_rps",
  UDP_AGENTLESS_VM: "udp_agentless_vm_backup",
  UDP_AGENTLESS_BACKUP: "udp_agentless_backup"
};

export const PRODUCT_TYPE = {
  CLOUD_DIRECT: "cloud_direct",
  UDP: "udp"
};
export const PRODUCT_TYPE_MAP = {
  [POLICY_TYPES.BAAS]: PRODUCT_TYPE.CLOUD_DIRECT,
  [POLICY_TYPES.DRAAS]: PRODUCT_TYPE.CLOUD_DIRECT,
  [POLICY_TYPES.HYPERVISOR]: PRODUCT_TYPE.CLOUD_DIRECT,
  [POLICY_TYPES.HYBRID]: PRODUCT_TYPE.UDP
};
export const SOURCE_TYPE_MAP = {
  [POLICY_TYPES.BAAS]: "machine",
  [POLICY_TYPES.DRAAS]: "machine",
  [POLICY_TYPES.HYPERVISOR]: "machine",
  [POLICY_TYPES.HYBRID]: "machine",
  [POLICY_TYPES.UDP_AGENT_BASED_WINDOWS]: "machine",
  [POLICY_TYPES.UDP_ORACLE_DATABASE]: "udp_oracle",
  [POLICY_TYPES.UDP_AGENT_BASED_LINUX]: "udp_linux",
  [POLICY_TYPES.UDP_UNC_NFC_BACKUP]: "shared_folder",
  [POLICY_TYPES.UDP_REPLICATE_FROM_REMOTE_RPS]: "machine",
  [POLICY_TYPES.UDP_AGENTLESS_VM]: "machine"
};
export const schedulemutable = policyType => {
  // this function is used to check if we need to render tasks accoding to the taskid
  // i.e render schedules specific to that task id
  switch (policyType) {
    case POLICY_TYPES.UDP_UNC_NFC_BACKUP:
    case POLICY_TYPES.UDP_AGENT_BASED_WINDOWS:
    case POLICY_TYPES.UDP_ORACLE_DATABASE:
    case POLICY_TYPES.UDP_AGENT_BASED_LINUX:
    case POLICY_TYPES.UDP_AGENTLESS_VM:
      return true;
    default:
      return false;
  }
};
export const DEFAULT_ICON = require("../../../../../../src/assets/images/icn_cloud.svg");
export const LOCAL_DISK_ICON = require("../../../../../../src/assets/images/icn_disk.svg");
export const SHARE_FOLDER_ICON = require("../../../../../../src/assets/images/icn_folder.svg");
export const RPS_ICON = require("../../../../../../src/assets/images/icn_rps.svg");

export const POLICY_PROTECTION_TYPES = {
  CLOUD_DIRECT_BAAS: {
    policy_type: POLICY_TYPES.BAAS,
    name: "cloud direct baas",
    description: "cloud direct baas",
    icon_path: require("../../../../../../src/assets/images/icn_cloud.svg"),
    entitlementsProductsPath: "clouddirect.baas.enable",
    productsStatePath: "clouddirect.state"
  },
  CLOUD_DIRECT_DRAAS: {
    policy_type: POLICY_TYPES.DRAAS,
    name: "cloud direct draas",
    description: "cloud direct draas",
    icon_path: require("../../../../../../src/assets/images/icn_cloud.svg"),
    icon: {
      iconName: "cloud"
    },
    entitlementsProductsPath: "clouddirect.draas.enable",
    productsStatePath: "clouddirect.state"
  },
  CLOUD_HYBRID_REPLICATION: {
    policy_type: POLICY_TYPES.HYBRID,
    name: "cloud hybrid replication",
    description: "cloud hybrid replication",
    icon_path: require("../../../../../../src/assets/images/icn_cloud.svg"),
    icon: {
      iconName: "cloud"
    },
    entitlementsProductsPath: "cloudhybrid.enable",
    productsStatePath: "cloudhybrid.state"
  },
  CLOUD_DIRECT_VMWARE: {
    policy_type: POLICY_TYPES.HYPERVISOR,
    key: "vmware",
    name: "VMware Agentless",
    description: "VMware Agentless",
    entitlementsProductsPath: "clouddirect.baas.enable",
    productsStatePath: "clouddirect.state",
    immutable: true
  },
  CLOUD_DIRECT_HYPERV: {
    policy_type: POLICY_TYPES.HYPERVISOR,
    key: "hyperv",
    name: "Hyper-V Agentless",
    // description: "Hyper-V Agentless",
    entitlementsProductsPath: "clouddirect.baas.enable",
    productsStatePath: "clouddirect.state",
    immutable: true
  },
  UDP_AGENT_BASED_WINDOWS: {
    policy_type: POLICY_TYPES.UDP_AGENT_BASED_WINDOWS,
    key: "agentWindows",
    name: "Agent-based Windows Backup to RPS",
    description:
      "Volume-level snapshot backup of Windows source to an on-premise Recovery Point Server.",
    icon: {
      local_disk: require("../../../../../../src/assets/images/icn_disk.svg"),
      share_folder: require("../../../../../../src/assets/images/icn_folder.svg"),
      recovery_point_server: require("../../../../../../src/assets/images/icn_rps.svg"),
      udp_assured_recovery_test: require("../../../../../../src/assets/images/icn_ar_test.svg"),
      udp_assured_security_scan: require("../../../../../../src/assets/images/icn_vm.svg"),
      instant_virtual_machine: require("../../../../../../src/assets/images/icn_vm.svg"),
      instant_virtual_disk: require("../../../../../../src/assets/images/icn_vm.svg"),
      udp_vsb: require("../../../../../../src/assets/images/icn_vmware.svg"),
      azure: require("../../../../../../src/assets/images/icn_cloud.svg"),
      ec2: require("../../../../../../src/assets/images/icn_cloud.svg"),
      vmware: require("../../../../../../src/assets/images/icn_vmware.svg"),
      hyperv: require("../../../../../../src/assets/images/icn_hyperv.svg"),
      nutanix: require("../../../../../../src/assets/images/icn_nutanix.svg")
    },
    entitlementsProductsPath: "udp.enable",
    productsStatePath: "udp.state"
  },
  UDP_ORACLE_DATABASE: {
    policy_type: POLICY_TYPES.UDP_ORACLE_DATABASE,
    key: "oracleDatabase",
    name: "Oracle Database Backup to RPS",
    description:
      "Backup of all datafiles and the control file that constitute an Oracle database to an on-premise Recovery Point Server.",
    icon: {
      local_disk: require("../../../../../../src/assets/images/icn_disk.svg"),
      recovery_point_server: require("../../../../../../src/assets/images/icn_rps.svg"),
      udp_assured_recovery_test: require("../../../../../../src/assets/images/icn_ar_test.svg")
    },
    entitlementsProductsPath: "udp.enable",
    productsStatePath: "udp.state"
  },
  UDP_AGENT_BASED_LINUX: {
    policy_type: POLICY_TYPES.UDP_AGENT_BASED_LINUX,
    key: "agentLinux",
    name: "Agent-based Linux Backup to RPS",
    description:
      "Volume-level snapshot backup of Linux source to an on-premise Recovery Point Server.",
    icon: {
      local_disk: require("../../../../../../src/assets/images/icn_disk.svg"),
      recovery_point_server: require("../../../../../../src/assets/images/icn_rps.svg"),
      udp_assured_recovery_test: require("../../../../../../src/assets/images/icn_ar_test.svg"),
      shared_folder: require("../../../../../../src/assets/images/icn_folder.svg"),
      share_folder: require("../../../../../../src/assets/images/icn_folder.svg")
    },
    entitlementsProductsPath: "udp.enable",
    productsStatePath: "udp.state"
  },
  UDP_UNC_NFC_BACKUP: {
    policy_type: POLICY_TYPES.UDP_UNC_NFC_BACKUP,
    key: "uncNfcBackup",
    name: "UNC/NFS Backup to RPS",
    description: "File and Folder backup to a Recovery Point Server.",
    icon_path: require("../../../../../../src/assets/images/icn_rps.svg"),
    entitlementsProductsPath: "udp.enable",
    productsStatePath: "udp.state"
  },
  UDP_REPLICATE_FROM_REMOTE_RPS: {
    policy_type: POLICY_TYPES.UDP_REPLICATE_FROM_REMOTE_RPS,
    key: "copyFromRemote",
    name: "Copy from remotely-managed RPS",
    description:
      "Receive copies of backups from a remote Recovery Point Server",
    icon_path: require("../../../../../../src/assets/images/icn_rps.svg"),
    entitlementsProductsPath: "udp.enable",
    productsStatePath: "udp.state"
  },
  UDP_AGENTLESS_VM: {
    policy_type: POLICY_TYPES.UDP_AGENTLESS_VM,
    key: "agentlessVM",
    name: "Agentless VM Backup to RPS",
    description:
      "Hypervisor based backup of VMs to an on-premise Recovery Point Server.",
    icon_path: {
      local_disk: require("../../../../../../src/assets/images/icn_disk.svg"),
      share_folder: require("../../../../../../src/assets/images/icn_folder.svg"),
      recovery_point_server: require("../../../../../../src/assets/images/icn_rps.svg")
    },
    entitlementsProductsPath: "udp.enable",
    productsStatePath: "udp.state"
  },
  UDP_AGENTLESS_BACKUP: {
    policy_type: POLICY_TYPES.UDP_AGENTLESS_BACKUP,
    key: "agentlessVM",
    name: "Agentless VM Backup to RPS",
    description:
      "Hypervisor based backup of VMs to an on-premise Recovery Point Server.",
    icon_path: {
      local_disk: require("../../../../../../src/assets/images/icn_disk.svg"),
      recovery_point_server: require("../../../../../../src/assets/images/icn_rps.svg")
    },
    entitlementsProductsPath: "udp.enable",
    productsStatePath: "udp.state"
  }
};

export const TASK_TYPES = {
  // cloud_direct_baas and cloud_direct_draas
  IMAGE: "cloud_direct_image_backup",

  // cloud_direct_baas
  FILE_FOLDER: "cloud_direct_file_folder_backup",
  SQL_SERVER: "cloud_direct_sql_server_backup",

  // cloud_hybrid_replication
  UDP_FROM_REMOTE: "udp_replication_from_remote",
  UDP_TO_REMOTE: "udp_replication_to_remote",

  VMWARE: "cloud_direct_vmware_backup",
  HYPERV: "cloud_direct_hyperv_backup",
  //udp task types
  UDP_UNC_NFC_PATH: "udp_unc_backup",
  UDP_REPLICATE_FROM_REMOTE_RPS: "udp_replication_from_remote",
  UDP_AGENT_BASED_WINDOWS: "udp_agentbase_backup",
  UDP_ORACLE_DATABASE: "udp_oracle_database_backup",
  ORACLE_DATABASE_BACKUP: "oracle_database_backup",
  UDP_AGENT_BASED_LINUX: "udp_linux_backup",
  COPY_TO_ANOTHER_DS: "udp_replication",
  COPY_TO_REMOTE_SITE: "copy_to_remote_site",
  UDP_AGENTLESS_VM: "udp_agentless_backup",
  EXCHANGE: "cloud_direct_exchange_backup",
  UDP_ASSURED_RECOVERY_TEST: "udp_assured_recovery_test",
  UDP_ASSURED_RECOVERY: "udp_assured_recovery",
  UDP_VIRTUAL_STANDBY: "udp_vsb",
  cloud_direct_file_backup: "cloud_direct_file_backup",
  UDP_ASSURED_SECURITY_SCAN: "udp_assured_security_scan"
};

export const TASK_TYPES_TO_PROPERTY_MAP = {
  [TASK_TYPES.IMAGE]: TASK_TYPES.IMAGE,
  [TASK_TYPES.FILE_FOLDER]: "cloud_direct_file_backup", // one of these are not like the others
  [TASK_TYPES.SQL_SERVER]: TASK_TYPES.SQL_SERVER,
  [TASK_TYPES.UDP_FROM_REMOTE]: TASK_TYPES.UDP_FROM_REMOTE,
  [TASK_TYPES.UDP_TO_REMOTE]: TASK_TYPES.UDP_TO_REMOTE,
  [TASK_TYPES.VMWARE]: TASK_TYPES.VMWARE,
  [TASK_TYPES.HYPERV]: TASK_TYPES.HYPERV,
  [TASK_TYPES.UDP_UNC_NFC_PATH]: TASK_TYPES.UDP_UNC_NFC_PATH,
  [TASK_TYPES.UDP_AGENT_BASED_WINDOWS]: TASK_TYPES.UDP_AGENT_BASED_WINDOWS,
  [TASK_TYPES.UDP_ORACLE_DATABASE]: TASK_TYPES.UDP_ORACLE_DATABASE,
  [TASK_TYPES.UDP_AGENT_BASED_LINUX]: TASK_TYPES.UDP_AGENT_BASED_LINUX,
  [TASK_TYPES.UDP_AGENTLESS_VM]: TASK_TYPES.UDP_AGENTLESS_VM,
  [TASK_TYPES.UDP_REPLICATE_FROM_REMOTE_RPS]:
    TASK_TYPES.UDP_REPLICATE_FROM_REMOTE_RPS,
  [TASK_TYPES.COPY_TO_ANOTHER_DS]: TASK_TYPES.COPY_TO_ANOTHER_DS,
  [TASK_TYPES.UDP_ASSURED_RECOVERY_TEST]: TASK_TYPES.UDP_ASSURED_RECOVERY_TEST,
  [TASK_TYPES.UDP_ASSURED_SECURITY_SCAN]: TASK_TYPES.UDP_ASSURED_SECURITY_SCAN,
  [TASK_TYPES.UDP_VIRTUAL_STANDBY]: TASK_TYPES.UDP_VIRTUAL_STANDBY,
  [TASK_TYPES.EXCHANGE]: TASK_TYPES.EXCHANGE
};

export const SCHEDULE_TYPES = {
  ONE_DAY: "1d",
  SIX_HOUR: "4d",
  ONE_HOUR: "1h",
  FIFTEEN_MINUTES: "4h",
  SIX_HOUR_DISABLED: "4d_disabled",
  ONE_HOUR_DISABLED: "1h_disabled",
  FIFTEEN_MINUTES_DISABLED: "4h_disabled",
  MERGE: "merge",
  REPLICATION: "replication",
  COPY: "copy",
  ASSURED: "ar",
  ASSURED_SECURITY: "as"
};

export const VOLUME_TYPES = {
  BAAS: "normal",
  DRAAS: "zero_copy",
  CLOUD_HYBRID: "cloud_hybrid_store"
};

// utils

// TODO: Clean up generateCronTab
// To be used once cronetab function is finalized
export function generateCronTab(schedule_type, startTime, selectedDays) {
  let generatedCronTab = "";
  let cronDays = EVERY_DAY_OF_WEEK;
  let splitedTime = startTime.split(":");
  splitedTime[1] =
    splitedTime[1].length === 2 && splitedTime[1].substr(0, 1) === "0"
      ? splitedTime[1].substr(1, 1)
      : splitedTime[1];
  splitedTime[0] =
    splitedTime[0].length === 2 && splitedTime[0].substr(0, 1) === "0"
      ? splitedTime[0].substr(1, 1)
      : splitedTime[0];

  if (selectedDays && selectedDays.length > 0) {
    cronDays = selectedDays;
  }

  switch (schedule_type) {
    case SCHEDULE_TYPES.ONE_DAY:
      generatedCronTab = `${splitedTime[1]} ${splitedTime[0]} * * ${cronDays}`;
      break;
    case SCHEDULE_TYPES.ONE_HOUR:
      generatedCronTab = `${splitedTime[1]} * * * ${cronDays}`;
      break;
    case SCHEDULE_TYPES.SIX_HOUR:
      generatedCronTab = `${splitedTime[1]} ${splitedTime[0]}/6 * * ${cronDays}`;
      break;
    case SCHEDULE_TYPES.FIFTEEN_MINUTES:
      generatedCronTab = `${splitedTime[1]}/15 * * * ${cronDays}`;
      break;
    default:
      generatedCronTab = `0 0 * * ${cronDays}`;
  }
  return generatedCronTab.trim();
}

export const destinationTemplate = {
  parent_id: null, // need to fill in
  task_type: null
};

export const throttleTemplate = ({
  destination_id = null,
  destination_name = null,
  task_id,
  task_type
}) => ({
  throttle_id: uuidv4(),
  throttle_type: "network",
  throttle_unit: "kbps",
  rate: 300,
  start_time: START_TIME,
  end_time: END_TIME,
  days_of_week: EVERY_DAY_OF_WEEK,
  destination_id,
  destination_name,
  task_id,
  task_type
});

export const destinationTemplateForTaskMap = {
  [TASK_TYPES.IMAGE]: {
    drives: ["*"],
    local_backup: {
      path: "",
      enabled: false
    }
  },
  [TASK_TYPES.FILE_FOLDER]: {
    path: [""],
    local_backup: {
      path: "",
      enabled: false
    },
    excludes: []
  },
  [TASK_TYPES.SQL_SERVER]: {
    sql_backup_type: "bak",
    sql_backup_default: true, // Checkbox: Backup default SQL Server instance
    // UI: comma separated, API: array of strings using comma deliminator
    sql_backup_local_instance: [], // Textfield: Backup Named SQL Server instance(s).
    sql_backup_path: "", // Textfield: Staging Drive or Path
    sql_verify_enabled: false, // Checkbox: Validate SQL backup
    local_backup: {
      path: "",
      enabled: false
    }
  },
  [TASK_TYPES.EXCHANGE]: {
    local_backup: {
      path: "",
      enabled: false
    }
  },
  [TASK_TYPES.UDP_FROM_REMOTE]: {
    retention_policy: {
      daily_backup: 7,
      weekly_backup: 5,
      monthly_backup: 12,
      manual_backup: 31
    },
    rps_policy_id: null
  },
  [TASK_TYPES.UDP_TO_REMOTE]: {
    remote_console_name: "",
    user_name: "",
    password: "",
    port: 8015,
    protocol: "https",
    remote_plan_global_uuid: null,
    remote_plan_uuid: null,
    remote_plan_name: null,
    remote_plan_rpspolicy_uuid: null,
    remote_plan_rpspolicy_name: null,
    retry_start: 10,
    retry_times: 3
  },
  [TASK_TYPES.UDP_AGENT_BASED_WINDOWS]: {
    protected_volumes: ["*"],
    retention_policy: {
      daily_backup: 7,
      weekly_backup: 5,
      monthly_backup: 12,
      manual_backup: 31
    },
    catalog: {
      custom: false,
      daily: false,
      monthly: false,
      weekly: false,
      annually: false,
      recovery_set: false
    },
    destination: {
      backup_destination_type: "recovery_point_server"
    },
    additional_settings: {
      snapshot_type: "software"
    }
  },
  [TASK_TYPES.UDP_ORACLE_DATABASE]: {
    retention_policy: {
      daily_backup: 7,
      weekly_backup: 5,
      monthly_backup: 12,
      manual_backup: 31
    },
    catalog: {
      custom: false,
      daily: false,
      monthly: false,
      weekly: false,
      annually: false,
      recovery_set: false
    },
    destination: {
      backup_destination_type: "recovery_point_server"
    }
  },
  [TASK_TYPES.UDP_AGENT_BASED_LINUX]: {
    protected_volumes: ["*"],
    retention_policy: {
      daily_backup: 7,
      weekly_backup: 5,
      monthly_backup: 12,
      manual_backup: 31
    },
    destination: {
      backup_destination_type: "recovery_point_server"
    },
    exclude_files: []
  },
  [TASK_TYPES.COPY_TO_ANOTHER_DS]: {
    retention_policy: {
      daily_backup: 7,
      weekly_backup: 5,
      monthly_backup: 12,
      manual_backup: 31
    },
    replication_site_type: "",
    retry_start: 10,
    retry_times: 3
  },
  [TASK_TYPES.UDP_AGENTLESS_VM]: {
    retention_policy: {
      daily_backup: 7,
      weekly_backup: 5,
      monthly_backup: 12,
      manual_backup: 31
    },
    catalog: {
      custom: false,
      daily: false,
      monthly: false,
      weekly: false,
      annually: false,
      recovery_set: false
    },
    destination: {
      backup_destination_type: "recovery_point_server"
    },
    additional_settings: {
      snapshot_type: "software"
    },
    advanced_settings: {
      vmware_snapshot: {
        vmware_snapshot_quiescence_method: "vmware_tools",
        take_snapshot_without_quescing_guest: true
      },
      vmware_transport: {
        select_method_by: "vmware",
        method_priorities: ["SAN", "HotAdd", "NBD", "NBDSSL"]
      },
      hyperv_snapshot: {
        using_snapshot_generated_by_microsoft_vss: true,
        place_in_save_state_before_snapshot: false
      }
      // nutanix_transport: {
      //   transport_method: "volume_group",
      //   take_snapshot_without_quescing_guest: false
      // }
    }
  },
  [TASK_TYPES.UDP_UNC_NFC_PATH]: {
    password: "",
    retention_policy: {
      daily_backup: 7,
      weekly_backup: 5,
      monthly_backup: 12,
      manual_backup: 31
    },
    catalog: {
      custom: false,
      daily: false,
      monthly: false,
      weekly: false,
      annually: false,
      recovery_set: false
    },
    destination: {
      backup_destination_type: "recovery_point_server"
    },
    exclusions: {
      exclude_names: []
    },
    proxy_id: "",
    username: ""
  },
  [TASK_TYPES.UDP_REPLICATE_FROM_REMOTE_RPS]: {
    retention_policy: {
      daily_backup: 7,
      weekly_backup: 5,
      monthly_backup: 12,
      manual_backup: 31
    },
    rps_policy_id: null,
    nat_server: null
  },
  [TASK_TYPES.UDP_ASSURED_RECOVERY_TEST]: {
    datastoreId: "",
    advanced_settings: {
      // verify_file_system: true,
      // run_check_disk_command: false,
      // heart_beat_settings: {
      //   timeout: "600",
      //   frequency: "15"
      // },
      // proxy_script: {
      //   is_success_exit_code: false,
      //   check_exit_code: false,
      //   exit_code: 0,
      //   script_path: "",
      //   timeout: 0
      // },
      // lbs_script: {
      //   post_script_on_server: "",
      //   post_script_on_vm: "",
      //   timeout: 0
      // }
    },
    test_type: "instant_virtual_machine",
    instant_virtual_machine_option: {
      hypervisor_type: "vmware",
      vm_setting: {
        cpu_count: "1"
      }
    },
    instant_virtual_disk_option: null,
    source_recovery_point_type: {
      daily_backup: false,
      weekly_backup: false,
      monthly_backup: false,
      latest_recovery_point: false
    }
  },
  [TASK_TYPES.UDP_ASSURED_SECURITY_SCAN]: {
    datastoreId: "",
    advanced_settings: {
      timeout: 1
    },
    instant_option: "instant_virtual_disk",
    instant_virtual_machine_option: null,
    instant_virtual_disk_option: null,
    source_recovery_point_type: {
      daily_backup: false,
      weekly_backup: false,
      monthly_backup: false,
      latest_recovery_point: false
    }
  },
  [TASK_TYPES.UDP_VIRTUAL_STANDBY]: {
    virtualization_server: {
      // virtualization_type: "hyperv"
    },
    vm_settings: {
      base_settings: {
        vm_name_prefix: "VM_",
        recovery_point_snapshot_count: "5",
        cpu_count: "1",
        memory: 1024,
        combine_unconvertes_sessions_to_single_snapshot: true
      },
      network_settings: {
        auto_assign_public_ip: true,
        same_adapters_as_source_last_backup: true,
        adapter: [
          {
            adapter_type: "",
            connected_network: ""
          }
        ]
      },
      storage_settings: {
        share_same_datastore: true
      }
    },
    additional_settings: {
      auto_start_vm: "false",
      heartbeat: {
        timeout: 30,
        frequency: 5
      },
      timeout_for_attaching_volume_group: 600,
      azure_customize_job_parameters: {
        thread_count: 4,
        buffer_size_kb: 4096
      }
    }
  }
};

export const daysOfWeek = [
  { label: "Sun", value: "0" },
  { label: "Mon", value: "1" },
  { label: "Tue", value: "2" },
  { label: "Wed", value: "3" },
  { label: "Thu", value: "4" },
  { label: "Fri", value: "5" },
  { label: "Sat", value: "6" }
];
export const FulldaysOfWeek = [
  { label: "sunday", value: "0" },
  { label: "monday", value: "1" },
  { label: "tuesday", value: "2" },
  { label: "wednesday", value: "3" },
  { label: "thursday", value: "4" },
  { label: "friday", value: "5" },
  { label: "saturday", value: "6" }
];

export const DEFAULT_SELECTED_DAYS = "0,1,2,3,4,5,6";

export const additionalSettingsFilesFolders = ["file", "directory", "path"];

// need to move to schedule template
export const createMergeSchedule = destination => ({
  schedule_id: uuidv4(),
  schedule_type: SCHEDULE_TYPES.MERGE,
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: START_TIME,
  end_time: END_TIME,
  schedule: {
    merge_schedule: {
      days_of_week: EVERY_DAY_OF_WEEK
    }
  },
  task_type: destination.task_type // ,
  // destination_name: null, // <-- probably don't need
  // same: false // <-- don't know what this is for
});

export const createBackupSchedule = (
  destination,
  task_id,
  schedule_type,
  backup_type,
  policy_type
) => {
  let scheduleObj = {
    [schedule_type + "_schedule"]: { backup_type: backup_type }
  };
  if (policy_type === protectionType.UDP_ORACLE_DATABASE) {
    scheduleObj[schedule_type + "_schedule"]["oracle_file_type"] =
      "full_database";
  }

  if (
    schedule_type === "daily" ||
    schedule_type === "custom" ||
    schedule_type === "hourly" ||
    schedule_type === "minute"
  )
    scheduleObj[schedule_type + "_schedule"]["days_of_week"] = "0,1,2,3,4,5,6";
  if (schedule_type === "weekly")
    scheduleObj[schedule_type + "_schedule"]["day_of_week"] = "5";
  if (schedule_type === "monthly") {
    scheduleObj[schedule_type + "_schedule"]["is_day_of_month_enable"] = true;
    scheduleObj[schedule_type + "_schedule"]["day_of_month"] = "01";
  }
  if (schedule_type === "custom") {
    scheduleObj[schedule_type + "_schedule"]["is_repeat_enable"] = true;
    scheduleObj[schedule_type + "_schedule"]["repeat_interval"] = {};
    scheduleObj[schedule_type + "_schedule"]["repeat_interval"][
      "interval_unit"
    ] = "hours";
    scheduleObj[schedule_type + "_schedule"]["repeat_interval"]["interval"] = 3;
  }
  return {
    schedule_id: uuidv4(),
    task_id: task_id,
    destination_id: destination.destination_id,
    start_time:
      schedule_type === "custom" ? REPLICATION_START_TIME : BACKUP_START_TIME,
    end_time: schedule_type === "custom" ? REPLICATION_END_TIME : null,
    task_type: destination.task_type,
    schedule_type: schedule_type,
    schedule: scheduleObj
  };
};

export const createUDPRemoteBackupSchedule = destination => ({
  schedule_id: uuidv4(),
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  schedule: {
    merge_schedule: {
      days_of_week: EVERY_DAY_OF_WEEK
    }
  },
  start_time: START_TIME,
  end_time: END_TIME,
  task_type: destination.task_type,
  schedule_type: "merge"
});

export const createReplicationSchedule = destination => ({
  schedule_id: uuidv4(),
  schedule_type: SCHEDULE_TYPES.REPLICATION,
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: REPLICATION_START_TIME,
  end_time: REPLICATION_END_TIME,
  schedule: {
    replication_schedule: {
      days_of_week: EVERY_DAY_OF_WEEK
    }
  },
  task_type: destination.task_type // ,
  // destination_name: null, // <-- probably don't need
  // same: false // <-- don't know what this is for
});

export const createCloudDirectSchedule = destination => ({
  schedule_id: uuidv4(),
  schedule_type: SCHEDULE_TYPES.ONE_DAY,
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: START_TIME,
  end_time: null,
  selectedDays: DEFAULT_SELECTED_DAYS,
  schedule: {
    cloud_direct_schedule: {
      crontab_string: generateCronTab(SCHEDULE_TYPES.ONE_DAY, START_TIME)
    }
  },
  task_type: destination.task_type // ,
  // destination_name: null, // <-- probably don't need
  // same: false // <-- don't know what this is for
});

export const createDisasterRecoverySchedule = destination => ({
  schedule_id: uuidv4(),
  schedule_type: SCHEDULE_TYPES.ONE_DAY,
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: START_TIME,
  end_time: null,
  selectedDays: DEFAULT_SELECTED_DAYS,
  schedule: {
    cloud_direct_schedule: {
      crontab_string: generateCronTab(SCHEDULE_TYPES.ONE_DAY, START_TIME)
    }
  },
  task_type: destination.task_type // ,
  // destination_name: null, // <-- probably don't need
  // same: false // <-- don't know what this is for
});

// const oneOfType = types => selectedTypes => {
//   return types.find(type => selectedTypes.includes(type)) === -1 ? types : [];
// };

const any = types => selectedTypes => _.difference(types, selectedTypes);

const none = () => () => [];

const policyTypeOptions = {
  //policy_type: [] //options
  cloud_direct_baas: (selectedTypes, cdDestinationOptions) => {
    if (
      selectedTypes.filter(task_type => task_type === TASK_TYPES.FILE_FOLDER)
        .length < cdDestinationOptions.length
    ) {
      return [
        ...any([TASK_TYPES.IMAGE, TASK_TYPES.SQL_SERVER, TASK_TYPES.EXCHANGE])(
          selectedTypes
        ),
        TASK_TYPES.FILE_FOLDER
      ];
    }
    return any([
      TASK_TYPES.IMAGE,
      TASK_TYPES.FILE_FOLDER,
      TASK_TYPES.SQL_SERVER,
      TASK_TYPES.EXCHANGE
    ])(selectedTypes);
  },
  cloud_direct_draas: any([TASK_TYPES.IMAGE]),
  cloud_hybrid_replication: any([TASK_TYPES.UDP_FROM_REMOTE]),
  cloud_direct_hypervisor: any([TASK_TYPES.VMWARE]),
  [POLICY_TYPES.UDP_UNC_NFC_BACKUP]: any([TASK_TYPES.UDP_UNC_NFC_PATH]),
  [POLICY_TYPES.UDP_AGENT_BASED_WINDOWS]: any([
    TASK_TYPES.UDP_AGENT_BASED_WINDOWS
  ]),
  [POLICY_TYPES.UDP_ORACLE_DATABASE]: any([TASK_TYPES.UDP_ORACLE_DATABASE]),
  [POLICY_TYPES.UDP_AGENT_BASED_LINUX]: any([TASK_TYPES.UDP_AGENT_BASED_LINUX]),
  [POLICY_TYPES.UDP_REPLICATE_FROM_REMOTE_RPS]: any([
    TASK_TYPES.UDP_REPLICATE_FROM_REMOTE_RPS
  ]),
  [POLICY_TYPES.UDP_AGENTLESS_VM]: any([TASK_TYPES.UDP_AGENTLESS_VM])
};

export const policyTypeScheduleOptions = {
  cloud_direct_baas: (destination, destinations, allDestinations) => {
    //TODO make the logic common
    /* const selectedDestination =
      allDestinations &&
      allDestinations.find(
        d => destination.destination_id === d.destination_id
      );
    return _.get(
      selectedDestination,
      "cloud_direct_volume.schedule_options",
      []
    ).length > 0
      ? selectedDestination.cloud_direct_volume.schedule_options
      : */ return [
      SCHEDULE_TYPES.ONE_DAY
    ];
  },
  cloud_direct_draas: (destination, destinations, allDestinations) => {
    /* const selectedDestination =
      allDestinations &&
      allDestinations.find(
        d => destination.destination_id === d.destination_id
      );
    return _.get(
      selectedDestination,
      "cloud_direct_volume.schedule_options",
      []
    ).length > 0
      ? selectedDestination.cloud_direct_volume.schedule_options
      : */ return [
      // SCHEDULE_TYPES.FIFTEEN_MINUTES,
      // SCHEDULE_TYPES.ONE_HOUR,
      // SCHEDULE_TYPES.SIX_HOUR,
      SCHEDULE_TYPES.ONE_DAY
    ];
  },
  cloud_hybrid_replication: [],
  cloud_direct_hypervisor: (destination, destinations, allDestinations) => {
    const selectedDestination =
      allDestinations &&
      allDestinations.find(
        d => destination.destination_id === d.destination_id
      );

    if (
      _.get(selectedDestination, "cloud_direct_volume.volume_type", null) ===
      VOLUME_TYPES.DRAAS
    ) {
      return policyTypeScheduleOptions.cloud_direct_draas(
        destination,
        destinations,
        allDestinations
      );
    }

    return policyTypeScheduleOptions.cloud_direct_baas(
      destination,
      destinations,
      allDestinations
    );
  }
};
export const policyTypeDisabledScheduleOptions = {
  cloud_direct_baas: () => [
    // SCHEDULE_TYPES.FIFTEEN_MINUTES_DISABLED,
    // SCHEDULE_TYPES.ONE_HOUR_DISABLED,
    // SCHEDULE_TYPES.SIX_HOUR_DISABLED
  ],
  cloud_direct_draas: () => [],
  cloud_hybrid_replication: [],
  cloud_direct_hypervisor: (destination, destinations, allDestinations) => {
    const selectedDestination =
      allDestinations &&
      allDestinations.find(
        d => destination.destination_id === d.destination_id
      );

    if (
      _.get(selectedDestination, "cloud_direct_volume.volume_type", null) ===
      VOLUME_TYPES.DRAAS
    ) {
      return policyTypeDisabledScheduleOptions.cloud_direct_draas();
    }

    return policyTypeDisabledScheduleOptions.cloud_direct_baas();
  }
};

const taskTypeLinkedOptions = {
  [TASK_TYPES.IMAGE]: none([]),
  [TASK_TYPES.FILE_FOLDER]: none([]),
  [TASK_TYPES.SQL_SERVER]: none([]),
  [TASK_TYPES.UDP_FROM_REMOTE]: any([TASK_TYPES.UDP_TO_REMOTE]),
  [TASK_TYPES.UDP_TO_REMOTE]: none([]),
  [TASK_TYPES.VMWARE]: none([]),
  [TASK_TYPES.UDP_REPLICATE_FROM_REMOTE_RPS]: any([
    TASK_TYPES.UDP_ASSURED_RECOVERY_TEST,
    TASK_TYPES.UDP_ASSURED_SECURITY_SCAN,
    TASK_TYPES.COPY_TO_ANOTHER_DS,
    TASK_TYPES.UDP_VIRTUAL_STANDBY
  ]),
  [TASK_TYPES.COPY_TO_ANOTHER_DS]: any([
    TASK_TYPES.UDP_ASSURED_RECOVERY_TEST,
    TASK_TYPES.UDP_ASSURED_SECURITY_SCAN,
    TASK_TYPES.COPY_TO_ANOTHER_DS,
    TASK_TYPES.UDP_VIRTUAL_STANDBY
  ]),
  [TASK_TYPES.UDP_AGENT_BASED_WINDOWS]: any([
    TASK_TYPES.UDP_ASSURED_RECOVERY_TEST,
    TASK_TYPES.UDP_ASSURED_SECURITY_SCAN,
    TASK_TYPES.COPY_TO_ANOTHER_DS,
    TASK_TYPES.UDP_VIRTUAL_STANDBY
  ]),
  [TASK_TYPES.UDP_ORACLE_DATABASE]: any([
    TASK_TYPES.UDP_ASSURED_RECOVERY_TEST,
    TASK_TYPES.UDP_ASSURED_SECURITY_SCAN,
    TASK_TYPES.COPY_TO_ANOTHER_DS
  ]),
  [TASK_TYPES.UDP_AGENTLESS_VM]: any([
    TASK_TYPES.UDP_ASSURED_RECOVERY_TEST,
    TASK_TYPES.UDP_ASSURED_SECURITY_SCAN,
    TASK_TYPES.COPY_TO_ANOTHER_DS,
    TASK_TYPES.UDP_VIRTUAL_STANDBY
  ]),
  [TASK_TYPES.UDP_UNC_NFC_PATH]: any([
    TASK_TYPES.UDP_ASSURED_RECOVERY_TEST,
    TASK_TYPES.UDP_ASSURED_SECURITY_SCAN,
    TASK_TYPES.COPY_TO_ANOTHER_DS
  ]),
  [TASK_TYPES.UDP_AGENT_BASED_LINUX]: any([
    TASK_TYPES.UDP_ASSURED_RECOVERY_TEST,
    TASK_TYPES.COPY_TO_ANOTHER_DS
  ])
};

export const createTaskId = () => uuidv4();

export const getPolicyOptions = (
  policyType,
  selectedOptions,
  cdDestinationOptions
) => {
  //hyperv should not have secondary tasks
  if (selectedOptions[0] === TASK_TYPES.HYPERV) {
    return [];
  }
  if (!policyTypeOptions.hasOwnProperty(policyType)) {
    return [];
  }
  return policyTypeOptions[policyType](selectedOptions, cdDestinationOptions);
};

//TODO fix this
export const getLinkedOptions = (
  taskType,
  selectedOptions,
  allTaskTypes,
  firstRoot
) => {
  if (!taskTypeLinkedOptions.hasOwnProperty(taskType)) {
    return [];
  }
  let filteredArray = taskTypeLinkedOptions[taskType](selectedOptions);
  if (
    firstRoot?.task_type &&
    ["local_disk", "share_folder", "shared_folder"].includes(
      firstRoot?.[firstRoot?.task_type]?.destination?.backup_destination_type
    )
  ) {
    filteredArray = filteredArray.filter(function(e) {
      return e !== "udp_replication";
    });
  }

  if (
    allTaskTypes &&
    allTaskTypes.length &&
    allTaskTypes.includes(TASK_TYPES.UDP_VIRTUAL_STANDBY)
  ) {
    filteredArray = filteredArray.filter(function(e) {
      return e !== TASK_TYPES.UDP_VIRTUAL_STANDBY;
    });
    return filteredArray;
  }

  return filteredArray;
};

export const convertToDestinationTree = createTreeConversionFunction(
  n => n.task_id,
  n => n.parent_id
);

export const TAB_LABELS = action_type => {
  const protect = {
    TAB1: {
      id: "policy.destination.heading.whatToProtect",
      tabkey: "whatToProtect",
      requireFields: [
        "protected_volumes",
        "proxy_id",
        "advanced_settings",
        "exclude_larger_than",
        "exclude_names",
        "lbs_server_id",
        "network_address",
        "prerequisites"
      ]
    },
    TAB2: {
      id: "policy.destination.heading.whereToProtect",
      tabkey: "whereToProtect",
      requireFields: [
        "destination_id",
        "datastore",
        "local_disk",
        "network_address",
        "rps_wrp_size_tb",
        "server_name",
        "share_folder"
      ]
    },
    TAB3: {
      id: "policy.destination.heading.whenToProtect",
      tabkey: "whenToProtect",
      requireFields: ["schedules", "retention_policy", "throttles"]
    },
    TAB4: {
      id: "policy.destination.heading.additionalSettings",
      tabkey: "additionalSettings",
      requireFields: ["additional_settings", "hypervisor_api"]
    }
  };
  const copy = {
    TAB1: {
      id: "policy.destination.heading.whatToCopy",
      tabkey: "whatToCopy",
      requireFields: ["task_type"]
    },
    TAB2: {
      id: "policy.destination.heading.whereToCopy",
      tabkey: "whereToCopy",
      requireFields: [
        "replication_site_id",
        "rps_server_id",
        "destination_id",
        "retry_start",
        "retry_times"
      ]
    },
    TAB3: {
      id: "policy.destination.heading.whenToCopy",
      tabkey: "whenToCopy",
      requireFields: ["schedules", "retention_policy", "throttles"]
    },
    TAB4: {
      id: "policy.destination.heading.additionalSettings",
      tabkey: "additionalSettings",
      requireFields: []
    }
  };

  const udp_assured_recovery_test = {
    TAB1: {
      id: "policy.destination.heading.whatToTest",
      tabkey: "whatToTest",
      requireFields: [
        "daily_backup",
        "weekly_backcup",
        "monthly_backup",
        "latest_recovery_point"
      ]
    },
    TAB2: {
      id: "policy.destination.heading.whereToTest",
      tabkey: "whereToTest",
      requireFields: [
        "windows_proxy",
        "linux_proxy",
        "vm_name_prefix",
        "vm_file_folder",
        "memory_size",
        "network_name",
        "site_id",
        "hypervisor_id",
        "hypervisor_type",
        "virtual_network_name",
        "ip_address",
        "mask",
        "default_gateway",
        "adapter_type",
        "virtual_hard_disk_file_folder_path",
        "datastore",
        "rps_server_id"
      ]
    },
    TAB3: {
      id: "policy.destination.heading.whenToTest",
      tabkey: "whenToTest",
      requireFields: ["schedules"]
    },
    TAB4: {
      id: "policy.destination.heading.additionalSettings",
      tabkey: "additionalSettings",
      requireFields: ["exit_code"]
    }
  };

  const udp_assured_security_scan = {
    TAB1: {
      id: "policy.destination.heading.whatToTest",
      tabkey: "whatToTest",
      requireFields: [
        "daily_backup",
        "weekly_backcup",
        "monthly_backup",
        "latest_recovery_point"
      ]
    },
    TAB2: {
      id: "policy.destination.heading.whereToTest",
      tabkey: "whereToTest",
      requireFields: [
        "windows_proxy",
        "linux_proxy",
        "vm_name_prefix",
        "vm_file_folder",
        "memory_size",
        "network_name",
        "site_id",
        "hypervisor_id",
        "hypervisor_type",
        "virtual_network_name",
        "ip_address",
        "mask",
        "default_gateway",
        "adapter_type",
        "virtual_hard_disk_file_folder_path",
        "datastore",
        "rps_server_id",
        "anti_virus_info"
      ]
    },
    TAB3: {
      id: "policy.destination.heading.whenToTest",
      tabkey: "whenToTest",
      requireFields: ["schedules"]
    },
    TAB4: {
      id: "policy.destination.heading.additionalSettings",
      tabkey: "additionalSettings",
      requireFields: ["timeout"]
    }
  };

  /**
   * Below we have the tab decleration for task type VSB in which we have instroduced one more parameter that is "requireFields" for tab restriction.
   * IMPORTANT: Add / remove fields whenever required to restrict the tab.
   */
  const virtualStandby = {
    TAB1: {
      id: "policy.destination.heading.source",
      tabkey: "Source",
      requireFields: ["task_mapped_id"]
    },
    TAB2: {
      id: "policy.destination.heading.virtualization_server",
      tabkey: "virtualizationServer",
      requireFields: ["site", "virtualization_server"]
    },
    TAB3: {
      id: "policy.destination.heading.virtual_machine",
      tabkey: "virtualMachine",
      requireFields: ["vm_settings"]
    },
    TAB4: {
      id: "policy.destination.heading.vsbadditionalSettings",
      tabkey: "vsbadditionalSettings",
      requireFields: ["additional_settings", "schedules"]
    }
  };

  switch (action_type) {
    case TASK_TYPES.UDP_AGENT_BASED_WINDOWS:
      return protect;
    case TASK_TYPES.COPY_TO_ANOTHER_DS:
      return copy;
    case TASK_TYPES.UDP_ASSURED_RECOVERY_TEST:
      return udp_assured_recovery_test;
    case TASK_TYPES.UDP_VIRTUAL_STANDBY:
      return virtualStandby;
    case TASK_TYPES.UDP_ASSURED_SECURITY_SCAN:
      return udp_assured_security_scan;
    default:
      return protect;
  }
};

export const CDBaas = {
  IMAGE: {
    TAB1: {
      requireFields: ["drives"]
    },
    TAB2: {
      requireFields: ["path", "destination_id"]
    },
    TAB3: {
      requireFields: ["schedules", "throttles"]
    },
    TAB4: {
      requireFields: []
    }
  },
  SQL_SERVER: {
    TAB1: {
      requireFields: ["sql_backup_path", "sql_backup_local_instance"]
    },
    TAB2: {
      requireFields: ["path", "destination_id"]
    },
    TAB3: {
      requireFields: ["schedules", "throttles"]
    },
    TAB4: {
      requireFields: []
    }
  },
  cloud_direct_file_backup: {
    TAB1: {
      requireFields: ["path"]
    },
    TAB2: {
      requireFields: ["path", "destination_id"]
    },
    TAB3: {
      requireFields: ["schedules", "throttles"]
    },
    TAB4: {
      requireFields: ["excludes"]
    }
  },
  EXCHANGE: {
    TAB1: {
      requireFields: []
    },
    TAB2: {
      requireFields: ["path", "destination_id"]
    },
    TAB3: {
      requireFields: ["schedules", "throttles"]
    },
    TAB4: {
      requireFields: []
    }
  }
};
export const CDDRaaS = {
  IMAGE: {
    TAB1: {
      requireFields: ["drives"]
    },
    TAB2: {
      requireFields: ["path", "destination_id"]
    },
    TAB3: {
      requireFields: ["schedules", "throttles"]
    },
    TAB4: {
      requireFields: []
    }
  }
};

export const createDiskReadThrottleSchedule = destination => ({
  throttle_id: uuidv4(),
  throttle_type: "disk_read",
  rate: "300",
  throttle_unit: "mb_min",
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: START_TIME,
  end_time: END_TIME,
  days_of_week: destination.days_of_week,
  task_type: destination.task_type
});

export const createNetworkThrottleSchedule = destination => ({
  throttle_id: uuidv4(),
  throttle_type: "network",
  rate: "500",
  throttle_unit: "kbps",
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: START_TIME,
  end_time: END_TIME,
  days_of_week: destination.days_of_week,
  task_type: destination.task_type
});

export const createDataTransferThrottleSchedule = destination => ({
  throttle_id: uuidv4(),
  throttle_type: "network",
  rate: "500",
  throttle_unit: "kbps",
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: START_TIME,
  end_time: END_TIME,
  days_of_week: "0,1,2,3,4,5,6",
  task_type: destination.task_type
});

export const SITE_TYPE = {
  GATEWAY: "gateway",
  REMOTE_SITE: "remote_site",
  ARCSERVE_CLOUD: "arcserve_cloud"
};

export const TASK_NAME_FORMATTED = {
  udp_agentbase_backup: "policy.type.heading.udp_agent_based_windows",
  udp_linux_backup: "policy.type.heading.udp_linux_backup",
  udp_agentless_backup: "policy.type.heading.udp_agentless_vm_backup",
  udp_oracle_database_backup: "policy.type.heading.udp_oracle_database",
  udp_unc_backup: "udp_unc_backup",
  udp_replication: "udp_replication",
  udp_replication_from_remote: "udp_replication_from_remote"
};

export const TASK_NAME = {
  udp_agentbase_backup: "Agent-based Windows Backup to RPS",
  udp_agentless_backup: "Agentless VM Backup to RPS",
  udp_unc_backup: "Backup - Files on UNC Path",
  udp_replication: "Copy To Another Data Store",
  udp_replication_from_remote: "Copy from a remotely managed RPS"
};
export const backupScheduleTypes = [
  "daily",
  "weekly",
  "monthly",
  "custom",
  "hourly",
  "minute"
];

export const BACK_UP_TYPE = { LOCAL: "local_disk" };

export const policyDestinationTypes = {
  udp_agent_based_windows: "udp_agentbase_backup",
  udp_agent_based_linux: "udp_linux_backup",
  udp_agentless_vm_backup: "udp_agentless_backup",
  udp_files_on_unc: "udp_unc_backup",
  udp_oracle_database: "udp_oracle_database_backup",
  copy_from_remotely_managed_rps: "udp_replication_from_remote"
};

export const policyTaskTypes = {
  udp_agentbase_backup: "udp_agent_based_windows",
  udp_linux_backup: "udp_agent_based_linux",
  udp_agentless_backup: "udp_agentless_vm_backup",
  udp_unc_backup: "udp_files_on_unc",
  udp_oracle_database_backup: "udp_oracle_database"
};

export const updateDestinationWithEncryptedPassword = (
  destinations,
  policyKey
) => {
  const updateDestinationValue = destinations.map(destinationItem => {
    if (policyTaskTypes[destinationItem.task_type]) {
      const backupDestinationType = _.get(
        destinationItem,
        `${policyKey}.destination.backup_destination_type`,
        null
      );
      const encyptionPasswordVal = _.get(
        destinationItem,
        `${policyKey}.destination.local_disk.encryption_password`,
        null
      );
      const encyptionPasswordValShareFolder = _.get(
        destinationItem,
        `${policyKey}.destination.share_folder.encryption_password`,
        null
      );
      const sessionPasswordVal = _.get(
        destinationItem,
        `${policyKey}.destination.datastore.session_password`,
        null
      );
      const updateDestinationItem = _.cloneDeep(destinationItem);
      if (
        backupDestinationType &&
        encyptionPasswordVal &&
        backupDestinationType === "local_disk" &&
        encyptionPasswordVal === ENCRYPTED_PASSWORD
      ) {
        updateDestinationItem[
          policyKey
        ].destination.local_disk.encryption_password = "";
      } else if (
        backupDestinationType &&
        encyptionPasswordValShareFolder &&
        backupDestinationType === "share_folder" &&
        encyptionPasswordValShareFolder === ENCRYPTED_PASSWORD
      ) {
        updateDestinationItem[
          policyKey
        ].destination.share_folder.encryption_password = "";
      } else if (
        backupDestinationType &&
        sessionPasswordVal &&
        backupDestinationType === "recovery_point_server" &&
        sessionPasswordVal === ENCRYPTED_PASSWORD
      ) {
        updateDestinationItem[
          policyKey
        ].destination.datastore.session_password = "";
      }
      return updateDestinationItem;
    }
    return destinationItem;
  });
  return updateDestinationValue;
};

export const SQL_BACKUP_TYPE = {
  MDB: "mdb",
  MDB_LOG: "mdb_log",
  BAK: "bak"
};

export const RECOVERY_METHOD_ICONS = {
  db: require("../../../../../../src/assets/images/icn_db.svg"),
  db_selected: require("../../../../../../src/assets/images/icn_db_s.svg"),
  drive: require("../../../../../../src/assets/images/icn_drive.svg"),
  drive_selected: require("../../../../../../src/assets/images/icn_drive_s.svg"),
  folder: require("../../../../../../src/assets/images/icn_folder.svg"),
  folder_selected: require("../../../../../../src/assets/images/icn_folder_s.svg"),
  machine: require("../../../../../../src/assets/images/icn_machine.svg"),
  machine_selected: require("../../../../../../src/assets/images/icn_machine_s.svg")
};

export const createAssuredRecoveryTestJobSchedule = destination => ({
  schedule_id: uuidv4(),
  schedule_type: SCHEDULE_TYPES.ASSURED,
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: START_TIME,
  end_time: END_TIME,
  schedule: {
    ar_schedule: {
      days_of_week: EVERY_DAY_OF_WEEK
    }
  },
  task_type: destination.task_type
});

export const createAssuredSecurityScanJobSchedule = destination => ({
  schedule_id: uuidv4(),
  schedule_type: SCHEDULE_TYPES.ASSURED_SECURITY,
  task_id: destination.task_id,
  destination_id: destination.destination_id,
  start_time: START_TIME,
  end_time: END_TIME,
  schedule: {
    as_schedule: {
      days_of_week: EVERY_DAY_OF_WEEK
    }
  },
  task_type: destination.task_type
});
